/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./components/DataTable/index";


import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Data
import dataTableData from "./data/dataTableData";
import Table from "examples/Tables/Table";
import { indicators, metrics, dateRanges, campaigns, apps, platforms, apiUrlTracking } from "commonData";
import { Slider } from "@mui/material";
import CampaignSearchBar from "./components/CampaignSearchBar";

import PlaceholderCard from "examples/Cards/PlaceholderCard";
import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAsana from "assets/images/small-logos/logo-asana.svg";
import logoInvision from "assets/images/small-logos/logo-invision.svg";
import axios from "axios";
import { apiUrlCampaigns } from "commonData";
import ReactIf from "reactif";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

function CampaignsList() {
  const [listData, setListData] = useState(null);
  const navigate = useNavigate();
  // ComplexProjectCard dropdown menu state
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const [premiumSupportMenu, setPremiumSupportMenu] = useState(null);
  const [designToolsMenu, setDesignToolsMenu] = useState(null);
  const [lookingGreatMenu, setLookingGreatMenu] = useState(null);
  const [developerFirstMenu, setDeveloperFirstMenu] = useState(null);

  // TeamProfileCard dropdown menu handlers
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const openPremiumSupportMenu = (event) => setPremiumSupportMenu(event.currentTarget);
  const closePremiumSupportMenu = () => setPremiumSupportMenu(null);
  const openDesignToolsMenu = (event) => setDesignToolsMenu(event.currentTarget);
  const closeDesignToolsMenu = () => setDesignToolsMenu(null);
  const openLookingGreatMenu = (event) => setLookingGreatMenu(event.currentTarget);
  const closeLookingGreatMenu = () => setLookingGreatMenu(null);
  const openDeveloperFirstMenu = (event) => setDeveloperFirstMenu(event.currentTarget);
  const closeDeveloperFirstMenu = () => setDeveloperFirstMenu(null);

  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>Save</MenuItem>
      <MenuItem onClick={close}>Another action</MenuItem>
      <MenuItem onClick={close}>Something else here</MenuItem>
    </Menu>
  );

  const [value, setValue] = useState([4, 8]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [influencerAgeValue, setInfluencerAgeValue] = useState([21, 38]);

  const handleInfluencerAgeValueChange = (event, newValue) => {
    setInfluencerAgeValue(newValue);
  };
  function influencerAgeValueText(value) {
    return value;
  }

  useEffect(() => {
    axios.post(apiUrlCampaigns + "/campaigns", {})
      .then((response) => {
        if (response.data.isSuccessful)
          setListData(response.data.data);
      });
  }, []);
  const auth = useAuthUser()
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox>
        <CampaignSearchBar></CampaignSearchBar>
        <Grid mt={1}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
              <Grid container>
                <ReactIf condition={auth.userType == 3}>
                  <Grid container item xs={12}>
                    <SoftBox display="flex" justifyContent="flex-end" sx={{ width: 1 }}>
                      <SoftButton variant="gradient" color="primary" size="small" onClick={() => { navigate("/advertiser/campaigns/new"); }}>
                        Add Campaign
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                </ReactIf>
                <Grid container item xs={12}>
                  <Grid id="123" container spacing={3} xs={12}>
                    {listData?.map((item, index) => (
                      <Grid item xs={12} md={6} lg={4}>
                        <ComplexProjectCard
                          image={item.campaignLogoPath}
                          title={item.campaignName}
                          description={item.description}
                          dateTime={new Date(item.endDate).toLocaleDateString("tr-TR")}
                          members={[team1, team2, team3, team4, team5]}
                          dropdown={{
                            action: openSlackBotMenu,
                            menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                          }}
                          myOnClick={() => {                            
                            if (auth.userType == 2)
                              navigate("/influencer/campaigns/detail?id=" + item.id.toString());
                            else
                              navigate("/advertiser/campaigns/detail?id=" + item.id.toString());
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <ReactIf condition={listData?.length == 0}>
                    <Grid container item xs={12}>
                      <PlaceholderCard icon={"block"} title={{ variant: "h5", text: "No projects yet" }} />
                    </Grid>
                  </ReactIf>
                </Grid>
              </Grid>
            </SoftBox>
          </Card></Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CampaignsList;
