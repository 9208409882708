// @mui material components
import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function PlatformsCell({ data }) {
  return (

    <Grid spacing={1} container>
      {data?.map((item, index) => (
        <Grid item>
          <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip title="" placement="left">
              <img src={("/images/platforms/" + item + ".png")} style={{ height: '20px' }} />
            </Tooltip>
          </SoftTypography>
        </Grid>
      ))}
    </Grid>
  );
}

export default PlatformsCell;
