/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditProduct page components
import ProductImage from "layouts/ecommerce/products/edit-product/components/ProductImage";
import ProductInfo from "layouts/ecommerce/products/edit-product/components/ProductInfo";
import Socials from "layouts/ecommerce/products/edit-product/components/Socials";
import Pricing from "layouts/ecommerce/products/edit-product/components/Pricing";
import BasicInfo from "../campaign-new/components/BasicInfo";
import form from "../campaign-new/schemas/form";
import validations from "../campaign-new/schemas/validations";
import { Card } from "@mui/material";
import { Formik, Form } from "formik";
//import initialValues from "../campaign-new/schemas/initialValues";
import Targeting from "../campaign-new/components/Targeting";
import Budget from "../campaign-new/components/Budget";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { apiUrlCampaigns } from "commonData";
import { useSearchParams } from "react-router-dom";
import ReactIf from "reactif";
import SoftSnackbar from "components/SoftSnackbar";





function EditCampaign() {
  const [processResultState, setProcessResultState] = useState({ title: "", content: "" });
  const [show, setShow] = useState(false);
  const toggleSnackbar = (e, title, content) => {
    if (e)
      setShow(!show);
    else {
      if (!show) {
        setProcessResultState({ title, content });
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 2000);
      }
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const formRef = useRef();
  const onSubmit = (values, actions) => {
    debugger;
    axios.put(apiUrlCampaigns + "/campaign",values)
      .then((response) => {
        debugger;
        actions.setSubmitting(false);
        if (response.data.isSuccessful)
          toggleSnackbar(null, response.data.message, response.data.detailMessage);
        //navigate("/advertiser/campaigns/edit?id=" + response.data.createdAggregationRootId.toString());
      }).catch((err) => {
        debugger;
        actions.setSubmitting(false);
      });
  };
  const handleSubmit = () => {

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const { formId, formField } = form;
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {

    axios.post(apiUrlCampaigns + "/campaign-detail", { campaignId: searchParams.get("id") })
      .then((response) => {
        if (response.data.isSuccessful) {
          debugger;
          // advertiserName: null
          // campaignStatusId: 2
          setInitialValues({
            "id": response.data.data.id,
            "advertiserId": response.data.data.advertiserId,
            "campaignLogoPath": response.data.data.campaignLogoPath,
            [formField.campaignName.name]: response.data.data.campaignName,
            [formField.description.name]: response.data.data.description,
            [formField.beginDate.name]: response.data.data.beginDate,
            [formField.endDate.name]: response.data.data.endDate,
            [formField.campaignTargetId.name]: response.data.data.campaignTargetId,
            [formField.webLinkUrl.name]: response.data.data.webLinkUrl,
            [formField.appStoreAppUrl.name]: response.data.data.appStoreAppUrl,
            [formField.googlePlayAppUrl.name]: response.data.data.googlePlayAppUrl,
            [formField.campaignBudgetCategoryId.name]: response.data.data.campaignBudgetCategoryId,
            [formField.fallbackUrl.name]: response.data.data.fallbackUrl,
            [formField.campaignBudgetTypeId.name]: response.data.data.campaignBudgetTypeId,
            [formField.totalAmount.name]: response.data.data.totalAmount,
            [formField.cpiAmount.name]: response.data.data.cpiAmount,
            [formField.cpcAmount.name]: response.data.data.cpcAmount
          });
        }
      });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftTypography variant="h4" fontWeight="medium">
                Campaign Details
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body2" color="text">
                  Edit your campaign
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox display="flex" justifyContent="flex-end">
                <SoftButton variant="gradient" color="info" onClick={handleSubmit}>
                  save
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>

          <ReactIf condition={initialValues != null}>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validations={validations[0]}
              onSubmit={onSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Grid container spacing={3}>
                    {/* <Grid item xs={12} lg={4}>
                      <ProductImage />
                    </Grid> */}
                    <Grid item xs={12} lg={12}>
                      <Card sx={{ overflow: "visible", height: "100%" }}>
                        <SoftBox p={3}>
                          <SoftTypography variant="h5">Basic Info</SoftTypography>
                          <BasicInfo formData={{
                            values,
                            touched,
                            formField,
                            errors,
                          }} />
                        </SoftBox>
                      </Card>
                    </Grid>






                    <Grid item xs={12} lg={6}>
                      <Card sx={{ overflow: "visible" }}>
                        <SoftBox p={3}>
                          <SoftTypography variant="h5">Targeting</SoftTypography>
                          <Targeting formData={{
                            values,
                            touched,
                            formField,
                            errors,
                          }} />
                        </SoftBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Card sx={{ overflow: "visible" }}>
                        <SoftBox p={3}>
                          <SoftTypography variant="h5">Budget</SoftTypography>
                          <Budget formData={{
                            values,
                            touched,
                            formField,
                            errors,
                          }} />
                        </SoftBox>
                      </Card>
                    </Grid>



                  </Grid>
                </Form>)}
            </Formik>
          </ReactIf>



        </Grid>
      </SoftBox>
      <Footer />
      <SoftSnackbar
        color="info"
        icon="notifications"
        title={processResultState.title}
        content={processResultState.content}
        dateTime=""
        open={show}
        close={toggleSnackbar}
      />
    </DashboardLayout>
  );
}

export default EditCampaign;
