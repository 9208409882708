/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { budgetTypes } from "commonData";
import ReactIf from "reactif";
import { apiUrlCampaigns } from "commonData";
import axios from "axios";
function Budget({ formData }) {
  const { formField, values, errors, touched } = formData;
  const {
    campaignBudgetTypeId,
    totalAmount,
    cpiAmount,
    cpcAmount,
    campaignBudgetCategoryId } = formField;

  const {
    campaignBudgetTypeId: campaignBudgetTypeIdV,
    totalAmount: totalAmountV,
    cpiAmount: cpiAmountV,
    cpcAmount: cpcAmountV,
    campaignBudgetCategoryId: campaignBudgetCategoryIdV } = values;

  const [budgetTypeState, setBudgetTypeState] = useState(values.campaignBudgetTypeId);
  const handleSetState = (event) => {
    setBudgetTypeState(event.target.value);
    values.campaignBudgetTypeId = event.target.value;
  };

  const [campaignBudgetCategoryState, setCampaignBudgetCategoryState] = useState(values.campaignBudgetCategoryId);
  const handleSetCampaignBudgetCategory = (event) => {
    setCampaignBudgetCategoryState(event.target.value);
    values.campaignBudgetCategoryId = event.target.value;
  };

  const [campaignBudgetCategories, setCampaignBudgetCategories] = useState([]);

  useEffect(() => {
    debugger;
    axios.get(apiUrlCampaigns + "/budget-category/select-list")
      .then((response) => {
        setCampaignBudgetCategories(response.data);
      })
  }, []);

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Adjust the Budget
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          You can choose fixed CPI/CPC or set total Budget
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625} spacing={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Budget Type
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput />} value={budgetTypeState} onChange={handleSetState}>
              {budgetTypes.map((item, index) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Target Category
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput />} value={campaignBudgetCategoryState} onChange={handleSetCampaignBudgetCategory}>
              {campaignBudgetCategories.map((item, index) => (
                <MenuItem value={item.id}>{item.text}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <ReactIf condition={budgetTypeState == 2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormField
                type={totalAmount.type}
                label={totalAmount.label}
                name={totalAmount.name}
                value={totalAmountV}
                placeholder={totalAmount.placeholder}
                error={errors.totalAmount && touched.totalAmount}
                success={totalAmountV.length > 0 && !errors.totalAmount}
              />
            </Grid>
          </Grid>
        </ReactIf>

        <ReactIf condition={budgetTypeState == 1}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormField
                type={cpiAmount.type}
                label={cpiAmount.label}
                name={cpiAmount.name}
                value={cpiAmountV}
                placeholder={cpiAmount.placeholder}
                error={errors.cpiAmount && touched.cpiAmount}
                success={cpiAmountV.length > 0 && !errors.cpiAmount}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type={cpcAmount.type}
                label={cpcAmount.label}
                name={cpcAmount.name}
                value={cpcAmountV}
                placeholder={cpcAmount.placeholder}
                error={errors.cpcAmount && touched.cpcAmount}
                success={cpcAmountV.length > 0 && !errors.cpcAmount}
              />
            </Grid>
          </Grid>
        </ReactIf>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Address
Budget.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Budget;
