import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

// Data
import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
import axios from "axios";
import { func } from "prop-types";
import { Skeleton } from "@mui/material";
import ReactIf from "reactif";
import { indicators, metrics, dateRanges, platforms, apiUrlTracking ,apiUrlCampaigns} from "commonData";

import SocialItem from "layouts/applications/analytics/components/SocialItem";


function InfluencerDashboardOverview() {
  const [apps, setApps] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  function getLookups() {
    axios.get(apiUrlCampaigns + "/select-list")
      .then((response) => {
        setCampaigns(response.data.map(function (m) {
          return { value: m.id, label: m.text };
        }));
      });
    axios.get(apiUrlCampaigns + "/apps/select-list")
      .then((response) => {
        setApps(response.data.map(function (m) {
          return { value: m.id, label: m.text };
        }));
      });
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    'border-radius': '10px',
    'border-color': '#ccc'
  };

  const [menu, setMenu] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const tabItemClicked = function (ix) {
    setActiveTab(ix);
    handleInputChange({ name: "impressionTypeId", value: countsData[ix].impressionTypeId });
    getOverviewStatisticsData();
  }
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);


  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >

      {dateRanges.filter(f => f.value != "Custom").map((item, index) => (
        <MenuItem key={index} onClick={event => {
          handleInputChange({ name: "dateRange", value: item.value });
          //getOverviewCountsData();
          //getOverviewStatisticsData();
          //getKPIsData();
          closeMenu();
        }}>{item.label}</MenuItem>
      ))}
    </Menu>
  );

  const renderCountItem = (index) => {
    if (countsData.length == 0) {
      return <Skeleton variant="rounded" height={84}></Skeleton>
    } else {
      return <Card onClick={() => tabItemClicked(index)} style={{ "cursor": "pointer" }}>
        <MiniStatisticsCard
          bgColor={activeTab === index ? "info" : undefined}
          title={{ text: countsData[index].label, fontWeight: "medium" }}
          count={countsData[index].amount}
          percentage={(countsData[index].changeRate != null ? {
            color: (countsData[index].changeRate.indexOf("-") == 0 ? "error" : "success"),
            text: countsData[index].changeRate
          } : {})}
          icon={{ color: "dark", component: countsData[index].iconCode }}
          fontSize={activeTab === index ? "1rem" : undefined}
        />
      </Card>
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { values } = breakpoints;
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  const [overviewChData, SetOverviewChData] = useState({
    labels: [],
    datasets: []
  });

  let selectedCampaigns = () => {
    var x = (campaigns.length == overviewQuery.campaigns.length ?
      [] :
      campaigns.filter(f => overviewQuery.campaigns.filter(f2 => f2 == f.value).length > 0));
    return x;
  }

  let selectedApps = () => {
    var x = (apps.length == overviewQuery.apps.length ?
      [] :
      apps.filter(f => overviewQuery.apps.filter(f2 => f2 == f.value).length > 0));
    return x;
  }

  let selectedPlatforms = () => {
    var x = (platforms.length == overviewQuery.apps.length ?
      [] :
      apps.filter(f => overviewQuery.platforms.filter(f2 => f2 == f.value).length > 0));
    return x;
  }

  let selectedTabGraphTitle = () => {
    if (countsData.length > 0)
      return countsData[activeTab].label;
    else return "";
  }

  const [countsData, SetCountsData] = useState([]);
  const [kpiData, setKpiData] = useState(null);
  const [percentageData, setPercentageData] = useState([]);

  const overviewChBadgeData = overviewChData.datasets.map((m) => { return { label: m.label, color: m.color }; });

  const [overviewQueryFormData, setOverviewQueryFormData] = useState(
    {
      "dateRange": "Today",
      "beginDate": null,
      "endDate": null,
      "campaigns": campaigns.map(m => m.value),
      "apps": apps.map(m => m.value),
      "platforms": platforms.map(m => m.value)
    });

  const [overviewQuery, setOverviewQuery] = useState(
    {
      "dateRange": "Today",
      "beginDate": null,
      "endDate": null,
      "campaigns": campaigns.map(m => m.value),
      "apps": apps.map(m => m.value),
      "platforms": platforms.map(m => m.value),
      "showPeriod": "Hourly",
      "impressionTypeId": 0
    });

  const [kpiQuery, setKpiQuery] = useState({ indicator: 1, metric: 1 });
  const [percentageByQuery, setPercentageByQuery] = useState({ indicator: 1 });


  useEffect(() => {
    getLookups();
    getOverviewStatisticsData();
    getOverviewCountsData();
    getKPIsData();
    getPercentageByData();
  }, [overviewQuery, kpiQuery, percentageByQuery]);

  function getOverviewStatisticsData() {
    if (overviewQuery.impressionTypeId != 0)
      if (overviewQuery.dateRange != "Custom" || (overviewQuery.beginDate && overviewQuery.endDate))
        axios.post(apiUrlTracking + "/Home/get-statistics", overviewQuery)
          .then((response) => {
            SetOverviewChData(response.data);
          });
  }

  function getOverviewCountsData() {
    //if (overviewQuery.impressionTypeId == 0)
    if (overviewQuery.dateRange != "Custom" || (overviewQuery.beginDate && overviewQuery.endDate))
      axios.post(apiUrlTracking + "/Home/get-counts", overviewQuery)
        .then((response) => {
          SetCountsData(response.data);
          if (overviewQuery.impressionTypeId == 0)
            handleInputChange({ name: "impressionTypeId", value: response.data[0].impressionTypeId });
        });
  }

  function getKPIsData() {
    if (overviewQuery.dateRange != "Custom" || (overviewQuery.beginDate && overviewQuery.endDate))
      axios.post(apiUrlTracking + "/Home/get-kpis", {
        dateRange: overviewQuery.dateRange,
        beginDate: overviewQuery.beginDate,
        endDate: overviewQuery.endDate,
        indicator: kpiQuery.indicator,
        metric: kpiQuery.metric
      })
        .then((response) => {
          setKpiData(response.data);
        });
  }

  function getPercentageByData() {
    if (overviewQuery.dateRange != "Custom" || (overviewQuery.beginDate && overviewQuery.endDate))
      axios.post(apiUrlTracking + "/Home/get-percentage-by", {
        dateRange: overviewQuery.dateRange,
        beginDate: overviewQuery.beginDate,
        endDate: overviewQuery.endDate,
        indicator: percentageByQuery.indicator
      })
        .then((response) => {
          setPercentageData(response.data);
        });
  }

  const handleFormInputChange = (e) => {
    setOverviewQueryFormData({
      ...overviewQueryFormData,
      [e.name]: e.value,
    });
  };

  const handleInputChange = (e) => {
    //const name = e.target.name 
    //const value = e.target.value 
    //const { name, value } = e;

    setOverviewQuery({
      ...overviewQuery,
      [e.name]: e.value,
    });
  };

  const handleKPIParamsChange = (e) => {
    setKpiQuery({
      ...kpiQuery,
      [e.name]: e.value,
    });
  };
  const handlePercentageByParamsChange = (e) => {
    setPercentageByQuery({
      ...percentageByQuery,
      [e.name]: e.value,
    });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <SoftBox mb={0} p={1}>
              <SoftTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                Overview
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SoftBox display="flex" justifyContent="flex-end" mb={0} mt={1} p={1}>
              {selectedCampaigns().map((item, index) => (
                <SoftBox mr={1}><SoftButton onClick={event => {
                  handleFormInputChange({ name: "campaigns", value: overviewQuery.campaigns.filter(f => f != item.value) });
                  setOverviewQuery({
                    ...overviewQuery,
                    ["campaigns"]: overviewQuery.campaigns.filter(f => f != item.value)
                  });
                }}>{item.label}<Icon>cancel</Icon></SoftButton></SoftBox>
              ))}
              {selectedApps().map((item, index) => (
                <SoftBox mr={1}><SoftButton onClick={event => {
                  handleFormInputChange({ name: "apps", value: overviewQuery.apps.filter(f => f != item.value) });
                  setOverviewQuery({
                    ...overviewQuery,
                    ["apps"]: overviewQuery.apps.filter(f => f != item.value)
                  });
                }}>{item.label}<Icon>cancel</Icon></SoftButton></SoftBox>
              ))}
              {selectedPlatforms().map((item, index) => (
                <SoftBox mr={1}><SoftButton onClick={event => {
                  handleFormInputChange({ name: "platforms", value: overviewQuery.platforms.filter(f => f != item.value) });
                  setOverviewQuery({
                    ...overviewQuery,
                    ["platforms"]: overviewQuery.platforms.filter(f => f != item.value)
                  });
                }}>{item.label}<Icon>cancel</Icon></SoftButton></SoftBox>
              ))}
              <SoftBox mr={3}>
                <SoftButton variant="outlined" color="secondary" onClick={handleOpen}>
                  FILTERS&nbsp;&nbsp;
                  <Icon>tune</Icon>
                </SoftButton>
              </SoftBox>


              <SoftButton variant="gradient" color="dark" onClick={openMenu}>
                {dateRanges.filter(f => f.value == overviewQuery.dateRange)[0].label}&nbsp;
                <Icon>expand_more</Icon>
              </SoftButton>
              {renderMenu}
            </SoftBox>
          </Grid>

        </Grid>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            {renderCountItem(0)}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {renderCountItem(1)}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {renderCountItem(2)}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {renderCountItem(3)}
          </Grid>
        </Grid>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <ReactIf condition={countsData.length > 0}>
              <DefaultLineChart
                title={selectedTabGraphTitle()}
                description={
                  <SoftBox display="flex" ml={-1}>
                    {overviewChBadgeData.map((item, index) => (
                      <SoftBadgeDot key={index} color={item.color} size="sm" badgeContent={item.label} />
                    ))}
                  </SoftBox>
                }
                chart={overviewChData}
                onPeriodChanged={function (newValue) {
                  var period = "";
                  if (newValue == 0)//hourly
                    period = "Hourly";
                  else if (newValue == 1)//daily
                    period = "Daily";
                  else if (newValue == 2)//weekly  
                    period = "Weekly";
                  handleInputChange({ name: "showPeriod", value: period });
                  //getOverviewStatisticsData();
                }}
              />
            </ReactIf>
            <ReactIf condition={countsData.length == 0}>
              <Skeleton variant="rounded" height={84}></Skeleton>
            </ReactIf>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SoftTypography variant="h6">KPIs</SoftTypography>
                <Tooltip title="See your ad performance by metrics" placement="bottom">
                  <SoftButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>priority_high</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
              <SoftBox p={2}>
                <Grid container spacing={3}>
                  {/* <Grid item xs={12} sm={3}>
            <FormField type="number" label="indication" defaultValue="99.00" />
          </Grid> */}
                  <Grid item xs={12} sm={4}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Indicator
                      </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                      defaultValue={indicators[0]}
                      onChange={event => {
                        handleKPIParamsChange({ name: "indicator", value: event.value });
                      }}
                      options={indicators}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Metric
                      </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                      defaultValue={metrics[0]}
                      onChange={event => {
                        handleKPIParamsChange({ name: "metric", value: event.value });
                      }}
                      options={metrics}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox p={2} pt={0}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ReactIf condition={kpiData == null}>
                      <Skeleton variant="text"></Skeleton>
                      <Skeleton variant="text"></Skeleton>
                      <Skeleton variant="text"></Skeleton>
                      <Skeleton variant="text"></Skeleton>
                      <Skeleton variant="text"></Skeleton>
                    </ReactIf>
                    <ReactIf condition={kpiData != null}>
                      <HorizontalBarChart title="" chart={kpiData} />
                    </ReactIf>
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ height: "100%" }}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SoftTypography variant="h6">Acquisition Percentage by </SoftTypography>
                <SoftSelect options={indicators}
                  defaultValue={indicators[0]}
                  onChange={event => {
                    handlePercentageByParamsChange({ name: "indicator", value: event.value });
                  }} />
                <Tooltip title="See how much traffic do you get from selected label" placement="bottom">
                  <SoftButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>priority_high</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
              <SoftBox p={2}>
                <ReactIf condition={percentageData.length == 0}>
                  <Skeleton variant="text"></Skeleton>
                  <Skeleton variant="text"></Skeleton>
                  <Skeleton variant="text"></Skeleton>
                  <Skeleton variant="text"></Skeleton>
                </ReactIf>
                <ReactIf condition={percentageData.length > 0}>
                  {percentageData.map((item, index) => (
                    <SocialItem key={index} title={item.label} percentage={item.value} />
                  ))}
                </ReactIf>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
      <div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <SoftTypography id="modal-modal-title" variant="h6" component="h2">
              Filter & Parameters
            </SoftTypography>
            <SoftBox mt={1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Date Range
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect

                    options={dateRanges}
                    value={{ label: (dateRanges.filter(f => f.value == overviewQueryFormData.dateRange)[0]?.label), value: overviewQueryFormData.dateRange }}
                    onChange={event => {
                      handleFormInputChange({ name: "dateRange", value: event.value });
                    }}
                  />

                  <Grid container spacing={3} display={overviewQueryFormData.dateRange == "Custom" ? "" : "none"}>
                    <Grid item xs={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Begin Date
                          </SoftTypography>
                        </SoftBox>
                        <SoftDatePicker value={overviewQueryFormData.beginDate}
                          onChange={selectedDate => {

                            handleFormInputChange({ name: "beginDate", value: selectedDate[0] });
                          }}
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            End Date
                          </SoftTypography>
                        </SoftBox>
                        <SoftDatePicker value={overviewQueryFormData.endDate}
                          onChange={selectedDate => {
                            handleFormInputChange({ name: "endDate", value: selectedDate[0] });
                          }} />
                      </SoftBox>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Campaigns
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        options={campaigns}
                        isMulti
                        size="large"
                        value={overviewQueryFormData.campaigns.map(function (item) {
                          return { value: item, label: campaigns.filter(f => f.value == item)[0]?.label }
                        })}
                        onChange={event => {
                          handleFormInputChange({ name: "campaigns", value: event.map(item => item.value) });
                        }}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Apps
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        options={apps}
                        isMulti
                        size="large"
                        value={overviewQueryFormData.apps.map(function (item) {
                          return { value: item, label: apps.filter(f => f.value == item)[0]?.label }
                        })}
                        onChange={event => {
                          handleFormInputChange({ name: "apps", value: event.map(item => item.value) });
                        }}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Platform & Channels
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        options={platforms}
                        isMulti
                        size="large"
                        value={overviewQueryFormData.platforms.map(function (item) {
                          return { value: item, label: platforms.filter(f => f.value == item)[0]?.label }
                        })}
                        onChange={event => {
                          handleFormInputChange({ name: "platforms", value: event.map(item => item.value) });
                        }}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>

              </Grid>
            </SoftBox>

            <SoftBox display="flex" justifyContent="flex-end" mt={3}>
              <SoftBox mr={1}>
                <SoftButton color="light" onClick={handleClose}>CANCEL</SoftButton>
              </SoftBox>
              <SoftButton variant="gradient" color="info" onClick={() => {
                setOverviewQuery({
                  ...overviewQuery,
                  ["dateRange"]: overviewQueryFormData.dateRange,
                  ["beginDate"]: overviewQueryFormData.beginDate,
                  ["endDate"]: overviewQueryFormData.endDate,
                  ["campaigns"]: overviewQueryFormData.campaigns,
                  ["apps"]: overviewQueryFormData.apps,
                  ["platforms"]: overviewQueryFormData.platforms
                });
                handleClose();
              }}>
                FILTER
              </SoftButton>
            </SoftBox>
          </Box>
        </Modal>
      </div>
    </DashboardLayout>
  );
}

export default InfluencerDashboardOverview;