/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";
import SoftButton from "components/SoftButton";
import { Face, Instagram, Twitter } from "@mui/icons-material";
import { Icon, Stack } from "@mui/material";
import YouTube from "@mui/icons-material/YouTube";
import Facebook from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";


import Divider from "@mui/material/Divider";
// Image
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ReactIf from "reactif";


function Header({ influencerDetail, platformChanged }) {
    const avatarStyles = {
        border: ({ borders: { borderWidth }, palette: { white } }) =>
            `${borderWidth[2]} solid ${white.main}`,
        cursor: "pointer",
        position: "relative",
        ml: -1.5,

        "&:hover, &:focus": {
            zIndex: "10",
        },
    };


    var navigate = useNavigate();
    const platforms = [
        { value: 1, label: "Facebook", key: "Facebook", logo: "https://img.icons8.com/color/64/facebook-new.png" },
        { value: 2, label: "Instagram", key: "Instagram", logo: "https://img.icons8.com/color/64/instagram-new--v1.png" },
        { value: 3, label: "Youtube", key: "Youtube", logo: "https://img.icons8.com/color/64/youtube-play.png" },
        { value: 4, label: "TikTok", key: "Tiktok", logo: "https://img.icons8.com/color/64/tiktok--v1.png" },
        { value: 5, label: "Twitter", key: "Twitter", logo: "https://img.icons8.com/ios-filled/64/twitterx--v1.png" },
        { value: 6, label: "Other Platforms", key: "OtherPlatforms", logo: "https://img.icons8.com/material-outlined/64/share.png" }];


    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState();

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    useEffect(() => {
        setTabValue(getPlatformTabIndex());
    }, [influencerDetail]);

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        // navigate("/advertiser/influencers/detail?id=" +);
        platformChanged(influencerDetail.otherPlatformIdAndUserNameList[newValue].value);

    };

    function getPlatformTabIndex() {
        var res = 0;
        if (influencerDetail != null)
            for (var i = 0; i < influencerDetail.otherPlatformIdAndUserNameList?.length; i++) {
                if (influencerDetail.platformId == influencerDetail.otherPlatformIdAndUserNameList[i].key) {
                    res = i;
                    break;
                }
            }
        return res;
    }

    function addToFavorites(){

    }

    function removeFromFavorites(){
  
    }

    return (
        influencerDetail == null ? <></>
            :
            <SoftBox position="relative">
                <DashboardNavbar absolute light />
                <SoftBox
                    display="flex"
                    alignItems="center"
                    position="relative"
                    minHeight="18.75rem"
                    borderRadius="xl"
                    sx={{
                        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.info.main, 0.6),
                                rgba(gradients.info.state, 0.6)
                            )}, url(${curved0})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        overflow: "hidden",
                    }}
                />
                <Card
                    sx={{
                        backdropFilter: `saturate(200%) blur(30px)`,
                        backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                        boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                        position: "relative",
                        mt: -8,
                        mx: 3,
                        py: 2,
                        px: 2,
                    }}
                >
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <SoftAvatar
                                src={influencerDetail.profilePicturePath}
                                alt="profile-image"
                                variant="rounded"
                                size="xl"
                                shadow="sm"
                            />
                        </Grid>
                        <Grid item>
                            <SoftBox height="100%" mt={0.5} lineHeight={1}>
                                <SoftTypography variant="h5" fontWeight="medium">
                                    {influencerDetail.fullName}
                                </SoftTypography>
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                    @{influencerDetail.username}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        {/* <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
                            <Stack direction="row" spacing={0.5} justifyContent="flex-end">
                                {influencerDetail.otherPlatformIdAndUserNameList.map(function (item) {
                                  return   <SoftButton variant="outlined" color="secondary" size="small">
                                        <img src={("/images/platforms/" + item.key + ".png")} style={{ height: '20px', opacity: '0.7' }} />
                                    </SoftButton>;
                                })}
                            </Stack>

                        </Grid> */}

                        <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
                            <AppBar position="static">
                                <Tabs
                                    orientation={tabsOrientation}
                                    value={tabValue}
                                    onChange={handleSetTabValue}
                                    sx={{ background: "transparent" }}
                                >

                                    {influencerDetail.otherPlatformIdAndUserNameList.map(function (item) {
                                        debugger;
                                        if (platforms.filter(f => f.value == item.key)[0]?.key == "Facebook") {
                                            return <Tab label="Facebook" icon={<Facebook />} />;
                                        }
                                        else if (platforms.filter(f => f.value == item.key)[0]?.key == "Twitter") {
                                            return <Tab label="Twitter" icon={<Twitter />} />;
                                        }
                                        else if (platforms.filter(f => f.value == item.key)[0]?.key == "Instagram") {
                                            return <Tab label="Instagram" icon={<Instagram />} />;
                                        }
                                        else if (platforms.filter(f => f.value == item.key)[0]?.key == "Youtube") {
                                            return <Tab label="Youtube" icon={<YouTube />} />;
                                        }
                                        else if (platforms.filter(f => f.value == item.key)[0]?.key == "Tiktok") {
                                            return <Tab label="TikTok" />;
                                        }
                                        else if (platforms.filter(f => f.value == item.key)[0]?.key == "Other Platforms") {
                                            return <Tab label="Other Platforms" />;
                                        }
                                    })}
                                </Tabs>
                            </AppBar>
                            <SoftBox display="flex" justifyContent="flex-end" m={2}>
                                <SoftBox display="flex" alignItems="center">
                                    {/* <SoftBox mt={0.5} pr={1}>
                                        <SoftBox mb={1} lineHeight={0}>
                                            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                                                Team members:
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox display="flex">
                                            <SoftAvatar src={team1} alt="team-1" size="sm" sx={avatarStyles} />
                                            <SoftAvatar src={team2} alt="team-1" size="sm" sx={avatarStyles} />
                                            <SoftAvatar src={team3} alt="team-1" size="sm" sx={avatarStyles} />
                                            <SoftAvatar src={team4} alt="team-1" size="sm" sx={avatarStyles} />
                                            <SoftAvatar src={team5} alt="team-1" size="sm" sx={avatarStyles} />
                                        </SoftBox>
                                    </SoftBox> */}
                                    {/* <SoftBox height="75%" alignSelf="flex-end">
                                        <Divider orientation="vertical" />
                                    </SoftBox> */}
                                    <SoftBox pl={1}>
                                        <ReactIf condition={!influencerDetail.inMyFavorites}>
                                            <SoftButton variant="outlined" color="primary" iconOnly onClick={addToFavorites}>
                                                <Icon>favorite</Icon>
                                            </SoftButton>
                                        </ReactIf>

                                        <ReactIf condition={influencerDetail.inMyFavorites}>
                                            <SoftButton variant="gradient" color="primary" iconOnly onClick={removeFromFavorites}>
                                                <Icon>favorite</Icon>
                                            </SoftButton>
                                        </ReactIf>
                                    </SoftBox>
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </Card>
            </SoftBox>
    );
}

export default Header;
