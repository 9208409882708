/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-images-viewer components
import ImgsViewer from "react-images-viewer";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Images
//import image1 from "https://play-lh.googleusercontent.com/qLRng-UBy-fAZpXQrsoJlQYOfBQXAbxtrYJiaGjODGqvT7qhmTjnGAB0VlRLJEpVF2Y=w240-h480-rw";

function ProductImages({campaignDetail}) {
  const [currentImage, setCurrentImage] = useState(campaignDetail?.campaignLogoPath);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  return (
    <SoftBox>
      <ImgsViewer
        imgs={[{ src: campaignDetail?.campaignLogoPath }]}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
      />

      <SoftBox
        component="img"
        src={campaignDetail?.campaignLogoPath}
        alt="Product Image"
        shadow="lg"
        borderRadius="lg"
        width="100%"
        onClick={openImgsViewer}
      />
      <SoftBox mt={2} pt={1}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <SoftBox
              component="img"
              id={0}
              src={campaignDetail?.campaignLogoPath}
              alt="small image 1"
              borderRadius="lg"
              shadow="md"
              width="100%"
              sx={{ cursor: "pointer", height: "100%", objectFit: "cover" }}
              onClick={handleSetCurrentImage}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default ProductImages;
