/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import Automotive from "layouts/dashboards/automotive";
import SmartHome from "layouts/dashboards/smart-home";
import VRDefault from "layouts/dashboards/virtual-reality/vr-default";
import VRInfo from "layouts/dashboards/virtual-reality/vr-info";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Security from "layouts/pages/account/security";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import NewProject from "layouts/pages/projects/new-project";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import SweetAlerts from "layouts/pages/sweet-alerts";
import Notifications from "layouts/pages/notifications";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import Analytics from "layouts/applications/analytics";
import Overview from "layouts/ecommerce/overview";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import ProductsList from "layouts/ecommerce/products/products-list";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import Referral from "layouts/ecommerce/referral";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpAsInfluencer from "layouts/authentication/sign-up/sign-up-as-influencer";
// import SignUpAsAdvertiser from "layouts/authentication/sign-up/sign-up-as-advertiser";
import ResetBasic from "layouts/authentication/reset-password/basic";
import ResetCover from "layouts/authentication/reset-password/cover";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import LockBasic from "layouts/authentication/lock/basic";
import LockCover from "layouts/authentication/lock/cover";
import LockIllustration from "layouts/authentication/lock/illustration";
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import VerificationCover from "layouts/authentication/2-step-verification/cover";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";


//
import AdvertiserDashboardOverview from "pages/advertiser/dashboard/overview/index"
import AdvertiserDashboardAcquisition from "pages/advertiser/dashboard/acquisition/index"
import AdvertiserDashboardCohort from "pages/advertiser/dashboard/cohort/index"
import AdvertiserDashboardFraud from "pages/advertiser/dashboard/fraud/index"
import InfluencersList from "pages/advertiser/inluencers/influencers-list";
import InfluencerDetail from "pages/advertiser/inluencers/influencer-detail";
import InfluencerFavorites from "pages/advertiser/inluencers/influencer-favorites";
import RequireAuth from "@auth-kit/react-router/RequireAuth";

import CampaignsInfluencerList from "pages/common/campaigns/campaigns-list";
import CampaignInfluencerDetail from "pages/common/campaigns/campaign-detail";
import CampaignsAdvertiserList from "pages/common/campaigns/campaigns-list";
import CampaignAdvertiserDetail from "pages/common/campaigns/campaign-detail";

import NewCampaign from "pages/advertiser/campaigns/campaign-new";
import EditCampaign from "pages/advertiser/campaigns/campaign-edit";

import SignIn from "pages/anonymous/sign-in/index";
import SignUpAsInfluencer from "pages/anonymous/sign-up-as-influencer/index";
import SignUpAsAdvertiser from "pages/anonymous/sign-up-as-advertiser/index";

import InfluencerDashboardOverview from "pages/influencer/dashboard/overview";

var signinPath = "/sign-in";

const advertiserRoutes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "advertiser/dashboards",
    icon: "dashboard",
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/advertiser/dashboards/overview",
        component: <RequireAuth fallbackPath={signinPath}>
          <AdvertiserDashboardOverview />
        </RequireAuth>,
      },
      {
        name: "Acquisition",
        key: "acquisition",
        route: "/advertiser/dashboards/acquisition",
        component: <RequireAuth fallbackPath={signinPath}>
          <AdvertiserDashboardAcquisition />
        </RequireAuth>

      },
      {
        name: "Cohort",
        key: "cohort",
        route: "/advertiser/dashboards/cohort",
        component: <RequireAuth fallbackPath={signinPath}>
          <AdvertiserDashboardCohort />
        </RequireAuth>,
      },
      {
        name: "Fraud",
        key: "fraud",
        route: "/advertiser/dashboards/fraud",
        component: <RequireAuth fallbackPath={signinPath}>
          <AdvertiserDashboardFraud />
        </RequireAuth>,
      }
    ],
  },
  { type: "title", title: "DISCOVER", key: "title-discover" },
  {
    type: "collapse",
    name: "Influencers",
    key: "advertiser/influencers",
    icon: <SpaceShip size="12px" />,
    collapse: [
      {
        name: "List",
        key: "list",
        route: "/advertiser/influencers/list",
        component: <RequireAuth fallbackPath={signinPath}>
          <InfluencersList />
        </RequireAuth>,
      },
      {
        name: "Favorites",
        key: "favorites",
        route: "/advertiser/influencers/favorites",
        component: <RequireAuth fallbackPath={signinPath}>
          <InfluencerFavorites />
        </RequireAuth>,
      }
    ],
  },
  { type: "title", title: "My", key: "title-my" },
  {
    type: "collapse",
    name: "Campaigns",
    key: "advertiser/campaigns",
    icon: <SpaceShip size="12px" />,
    collapse: [
      {
        name: "List",
        key: "list",
        route: "/advertiser/campaigns/list",
        component: <RequireAuth fallbackPath={signinPath}>
          <CampaignsAdvertiserList />
        </RequireAuth>,
      }
    ],
  },
  // {
  //   name: "new-campaign",
  //   key: "new-campaign",
  //   route: "advertiser/new-campaign",
  //   component: <NewCampaign />,
  // },
  {
    name: "edit-campaign",
    key: "edit-campaign",
    route: "advertiser/campaigns/edit",
    component: <EditCampaign />,
  },
  {
    name: "Detail",
    key: "detail",
    route: "/advertiser/campaigns/new",
    component: <RequireAuth fallbackPath={signinPath}>
      <NewCampaign />
    </RequireAuth>,
  },
  {
    name: "Detail",
    key: "detail",
    route: "/advertiser/campaigns/detail",
    component: <RequireAuth fallbackPath={signinPath}>
      <CampaignAdvertiserDetail />
    </RequireAuth>,
  },
  {
    name: "Detail",
    key: "detail",
    route: "/advertiser/influencers/detail",
    component: <RequireAuth fallbackPath={signinPath}>
      <InfluencerDetail />
    </RequireAuth>,
  }
];


const influencerRoutes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "influencer/dashboards",
    icon: "dashboard",
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/influencer/dashboards/overview",
        component: <RequireAuth fallbackPath={signinPath}>
          <InfluencerDashboardOverview />
        </RequireAuth>,
      }
    ],
  },
  { type: "title", title: "DISCOVER", key: "title-discover" },
  {
    type: "collapse",
    name: "Campaigns",
    key: "influencer/campaigns",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "List",
        key: "list",
        route: "/influencer/campaigns/list",
        component: <RequireAuth fallbackPath={signinPath}>
          <CampaignsInfluencerList />
        </RequireAuth>,
      }
    ],
  },
  {
    name: "Detail",
    key: "detail",
    route: "/influencer/campaigns/detail",
    component: <RequireAuth fallbackPath={signinPath}>
      <CampaignInfluencerDetail />
    </RequireAuth>,
  }
];

const anonymousRoutes = [
  {
    name: "sign-in",
    key: "sign-in",
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    name: "sign-up",
    key: "sign-up",
    route: "/sign-up-as-influencer",
    component: <SignUpAsInfluencer />,
  },
  {
    name: "sign-up",
    key: "sign-up",
    route: "/sign-up-as-advertiser",
    component: <SignUpAsAdvertiser />,
  }
];


export { influencerRoutes, advertiserRoutes, anonymousRoutes };


// {
//   name: "sign-up",
//   key: "sign-up",
//   route: "/sign-up",
//   component: <SignUpIllustration />,
// },
// {
//   type: "collapse",
//   name: "Dashboards",
//   key: "dashboards",
//   icon: <Shop size="12px" />,
//   collapse: [
//     {
//       name: "Default",
//       key: "default",
//       route: "/dashboards/default",
//       component: <Default />,
//     },
//     {
//       name: "Automotive",
//       key: "automotive",
//       route: "/dashboards/automotive",
//       component: <Automotive />,
//     },
//     {
//       name: "Smart Home",
//       key: "smart-home",
//       route: "/dashboards/smart-home",
//       component: <SmartHome />,
//     },
//     {
//       name: "Virtual Reality",
//       key: "virtual-reality",
//       collapse: [
//         {
//           name: "VR Default",
//           key: "vr-default",
//           route: "/dashboards/virtual-reality/default",
//           component: <VRDefault />,
//         },
//         {
//           name: "VR Info",
//           key: "vr-info",
//           route: "/dashboards/virtual-reality/info",
//           component: <VRInfo />,
//         },
//       ],
//     },
//     { name: "CRM", key: "crm", route: "/dashboards/crm", component: <CRM /> },
//   ],
// },
// { type: "title", title: "Pages", key: "title-pages" },
// {
//   type: "collapse",
//   name: "Pages",
//   key: "pages",
//   icon: <Office size="12px" />,
//   collapse: [
//     {
//       name: "Profile",
//       key: "profile",
//       collapse: [
//         {
//           name: "Profile Overview",
//           key: "profile-overview",
//           route: "/pages/profile/profile-overview",
//           component: <ProfileOverview />,
//         },
//         {
//           name: "Teams",
//           key: "teams",
//           route: "/pages/profile/teams",
//           component: <Teams />,
//         },
//         {
//           name: "All Projects",
//           key: "all-projects",
//           route: "/pages/profile/all-projects",
//           component: <AllProjects />,
//         },
//       ],
//     },
//     {
//       name: "Users",
//       key: "users",
//       collapse: [
//         {
//           name: "Reports",
//           key: "reports",
//           route: "/pages/users/reports",
//           component: <Reports />,
//         },
//         {
//           name: "New User",
//           key: "new-user",
//           route: "/pages/users/new-user",
//           component: <NewUser />,
//         },
//       ],
//     },
//     {
//       name: "Account",
//       key: "account",
//       collapse: [
//         {
//           name: "Settings",
//           key: "settings",
//           route: "/pages/account/settings",
//           component: <Settings />,
//         },
//         {
//           name: "Billing",
//           key: "billing",
//           route: "/pages/account/billing",
//           component: <Billing />,
//         },
//         {
//           name: "Invoice",
//           key: "invoice",
//           route: "/pages/account/invoice",
//           component: <Invoice />,
//         },
//         {
//           name: "Security",
//           key: "security",
//           route: "/pages/account/security",
//           component: <Security />,
//         },
//       ],
//     },
//     {
//       name: "Projects",
//       key: "projects",
//       collapse: [
//         {
//           name: "General",
//           key: "general",
//           route: "/pages/projects/general",
//           component: <General />,
//         },
//         {
//           name: "Timeline",
//           key: "timeline",
//           route: "/pages/projects/timeline",
//           component: <Timeline />,
//         },
//         {
//           name: "New Project",
//           key: "new-project",
//           route: "/pages/projects/new-project",
//           component: <NewProject />,
//         },
//       ],
//     },
//     {
//       name: "Pricing Page",
//       key: "pricing-page",
//       route: "/pages/pricing-page",
//       component: <PricingPage />,
//     },
//     { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
//     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
//     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
//     {
//       name: "Sweet Alerts",
//       key: "sweet-alerts",
//       route: "/pages/sweet-alerts",
//       component: <SweetAlerts />,
//     },
//     {
//       name: "Notfications",
//       key: "notifications",
//       route: "/pages/notifications",
//       component: <Notifications />,
//     },
//   ],
// },
// {
//   type: "collapse",
//   name: "Applications",
//   key: "applications",
//   icon: <SettingsIcon size="12px" />,
//   collapse: [
//     {
//       name: "Kanban",
//       key: "kanban",
//       route: "/applications/kanban",
//       component: <Kanban />,
//     },
//     {
//       name: "Wizard",
//       key: "wizard",
//       route: "/applications/wizard",
//       component: <Wizard />,
//     },
//     {
//       name: "Data Tables",
//       key: "data-tables",
//       route: "/applications/data-tables",
//       component: <DataTables />,
//     },
//     {
//       name: "Calendar",
//       key: "calendar",
//       route: "/applications/calendar",
//       component: <Calendar />,
//     },
//     {
//       name: "Analytics",
//       key: "analytics",
//       route: "/applications/analytics",
//       component: <Analytics />,
//     },
//   ],
// },
// {
//   type: "collapse",
//   name: "Ecommerce",
//   key: "ecommerce",
//   icon: <Basket size="12px" />,
//   collapse: [
//     {
//       name: "Overview",
//       key: "overview",
//       route: "/ecommerce/overview",
//       component: <Overview />,
//     },
//     {
//       name: "Products",
//       key: "products",
//       collapse: [
//         {
//           name: "New Product",
//           key: "new-product",
//           route: "/ecommerce/products/new-product",
//           component: <NewProduct />,
//         },
//         {
//           name: "Edit Product",
//           key: "edit-product",
//           route: "/ecommerce/products/edit-product",
//           component: <EditProduct />,
//         },
//         {
//           name: "Product Page",
//           key: "product-page",
//           route: "/ecommerce/products/product-page",
//           component: <ProductPage />,
//         },
//         {
//           name: "Products List",
//           key: "products-list",
//           route: "/ecommerce/products/products-list",
//           component: <ProductsList />,
//         },
//       ],
//     },
//     {
//       name: "Orders",
//       key: "orders",
//       collapse: [
//         {
//           name: "Order List",
//           key: "order-list",
//           route: "/ecommerce/orders/order-list",
//           component: <OrderList />,
//         },
//         {
//           name: "Order Details",
//           key: "order-details",
//           route: "/ecommerce/orders/order-details",
//           component: <OrderDetails />,
//         },
//       ],
//     },
//     {
//       name: "Referral",
//       key: "referral",
//       route: "/ecommerce/referral",
//       component: <Referral />,
//     },
//   ],
// },
// {
//   type: "collapse",
//   name: "Authentication",
//   key: "authentication",
//   icon: <Document size="12px" />,
//   collapse: [
//     {
//       name: "Sign In",
//       key: "sign-in",
//       collapse: [
//         {
//           name: "Basic",
//           key: "basic",
//           route: "/authentication/sign-in/basic",
//           component: <SignInBasic />,
//         },
//         {
//           name: "Cover",
//           key: "cover",
//           route: "/authentication/sign-in/cover",
//           component: <SignInCover />,
//         },
//         {
//           name: "Illustration",
//           key: "illustration",
//           route: "/authentication/sign-in/illustration",
//           component: <SignInIllustration />,
//         },
//       ],
//     },
//     {
//       name: "Sign Up",
//       key: "sign-up",
//       collapse: [
//         {
//           name: "Basic",
//           key: "basic",
//           route: "/authentication/sign-up/basic",
//           component: <SignUpBasic />,
//         },
//         {
//           name: "Cover",
//           key: "cover",
//           route: "/authentication/sign-up/cover",
//           component: <SignUpCover />,
//         },
//         {
//           name: "Illustration",
//           key: "illustration",
//           route: "/authentication/sign-up/illustration",
//           component: <SignUpIllustration />,
//         },
//       ],
//     },
//     {
//       name: "Reset Password",
//       key: "reset-password",
//       collapse: [
//         {
//           name: "Basic",
//           key: "basic",
//           route: "/authentication/reset-password/basic",
//           component: <ResetBasic />,
//         },
//         {
//           name: "Cover",
//           key: "cover",
//           route: "/authentication/reset-password/cover",
//           component: <ResetCover />,
//         },
//         {
//           name: "Illustration",
//           key: "illustration",
//           route: "/authentication/reset-password/illustration",
//           component: <ResetIllustration />,
//         },
//       ],
//     },
//     {
//       name: "Lock",
//       key: "lock",
//       collapse: [
//         {
//           name: "Basic",
//           key: "basic",
//           route: "/authentication/lock/basic",
//           component: <LockBasic />,
//         },
//         {
//           name: "Cover",
//           key: "cover",
//           route: "/authentication/lock/cover",
//           component: <LockCover />,
//         },
//         {
//           name: "Illustration",
//           key: "illustration",
//           route: "/authentication/lock/illustration",
//           component: <LockIllustration />,
//         },
//       ],
//     },
//     {
//       name: "2-Step Verification",
//       key: "2-step-verification",
//       collapse: [
//         {
//           name: "Basic",
//           key: "basic",
//           route: "/authentication/verification/basic",
//           component: <VerificationBasic />,
//         },
//         {
//           name: "Cover",
//           key: "cover",
//           route: "/authentication/verification/cover",
//           component: <VerificationCover />,
//         },
//         {
//           name: "Illustration",
//           key: "illustration",
//           route: "/authentication/verification/illustration",
//           component: <VerificationIllustration />,
//         },
//       ],
//     },
//     {
//       name: "Error",
//       key: "error",
//       collapse: [
//         {
//           name: "Error 404",
//           key: "error-404",
//           route: "/authentication/error/404",
//           component: <Error404 />,
//         },
//         {
//           name: "Error 500",
//           key: "error-500",
//           route: "/authentication/error/500",
//           component: <Error500 />,
//         },
//       ],
//     },
//   ],
// },
// { type: "divider", key: "divider-1" },
// { type: "title", title: "Docs", key: "title-docs" },
// {
//   type: "collapse",
//   name: "Basic",
//   key: "basic",
//   icon: <SpaceShip size="12px" />,
//   collapse: [
//     {
//       name: "Getting Started",
//       key: "getting-started",
//       collapse: [
//         {
//           name: "Overview",
//           key: "overview",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/overview/soft-ui-dashboard/",
//         },
//         {
//           name: "License",
//           key: "license",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/license/soft-ui-dashboard/",
//         },
//         {
//           name: "Quick Start",
//           key: "quick-start",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/quick-start/soft-ui-dashboard/",
//         },
//         {
//           name: "Build Tools",
//           key: "build-tools",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/build-tools/soft-ui-dashboard/",
//         },
//       ],
//     },
//     {
//       name: "Foundation",
//       key: "foundation",
//       collapse: [
//         {
//           name: "Colors",
//           key: "colors",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/colors/soft-ui-dashboard/",
//         },
//         {
//           name: "Grid",
//           key: "grid",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/grid/soft-ui-dashboard/",
//         },
//         {
//           name: "Typography",
//           key: "base-typography",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/base-typography/soft-ui-dashboard/",
//         },
//         {
//           name: "Borders",
//           key: "borders",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/borders/soft-ui-dashboard/",
//         },
//         {
//           name: "Box Shadows",
//           key: "box-shadows",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/box-shadows/soft-ui-dashboard/",
//         },
//         {
//           name: "Functions",
//           key: "functions",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/functions/soft-ui-dashboard/",
//         },
//         {
//           name: "Routing System",
//           key: "routing-system",
//           href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/routing-system/soft-ui-dashboard/",
//         },
//       ],
//     },
//   ],
// },
// {
//   type: "collapse",
//   name: "Components",
//   key: "components",
//   icon: <CustomerSupport size="12px" />,
//   collapse: [
//     {
//       name: "Alerts",
//       key: "alerts",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/alerts/soft-ui-dashboard/",
//     },
//     {
//       name: "Avatar",
//       key: "avatar",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/avatar/soft-ui-dashboard/",
//     },
//     {
//       name: "Badge",
//       key: "badge",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/badge/soft-ui-dashboard/",
//     },
//     {
//       name: "Badge Dot",
//       key: "badge-dot",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/badge-dot/soft-ui-dashboard/",
//     },
//     {
//       name: "Box",
//       key: "box",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/box/soft-ui-dashboard/",
//     },
//     {
//       name: "Buttons",
//       key: "buttons",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/buttons/soft-ui-dashboard/",
//     },
//     {
//       name: "Date Picker",
//       key: "date-picker",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/datepicker/soft-ui-dashboard/",
//     },
//     {
//       name: "Dropzone",
//       key: "dropzone",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/dropzone/soft-ui-dashboard/",
//     },
//     {
//       name: "Editor",
//       key: "editor",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/quill/soft-ui-dashboard/",
//     },
//     {
//       name: "Input",
//       key: "input",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/input/soft-ui-dashboard/",
//     },
//     {
//       name: "Pagination",
//       key: "pagination",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/pagination/soft-ui-dashboard/",
//     },
//     {
//       name: "Progress",
//       key: "progress",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/progress/soft-ui-dashboard/",
//     },
//     {
//       name: "Select",
//       key: "select",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/select/soft-ui-dashboard/",
//     },
//     {
//       name: "Snackbar",
//       key: "snackbar",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/snackbar/soft-ui-dashboard/",
//     },
//     {
//       name: "Social Button",
//       key: "social-button",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/social-buttons/soft-ui-dashboard/",
//     },
//     {
//       name: "Tag Input",
//       key: "tag-input",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/tag-input/soft-ui-dashboard/",
//     },
//     {
//       name: "Typography",
//       key: "typography",
//       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/typography/soft-ui-dashboard/",
//     },
//   ],
// },
// {
//   type: "collapse",
//   name: "Change Log",
//   key: "changelog",
//   href: "https://mui.com/store/items/soft-ui-pro-dashboard/",
//   icon: <CreditCard size="12px" />,
//   noCollapse: true,
// },