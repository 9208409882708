/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import ReactIf from "reactif";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Card, Modal } from "@mui/material";
import { apiUrlInfluencers } from "commonData";
import { platforms } from "commonData";
import SoftAvatar from "components/SoftAvatar";
import JoinedCampaignUrls from "../JoinedCampaignUrls";
import SoftSnackbar from "components/SoftSnackbar";

function ProductInfo({ campaignDetail }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    'border-radius': '10px',
    'border-color': '#ccc'
  };
  const frameOptions = [
    { value: "aluminium", label: "Aluminium" },
    { value: "carbon", label: "Carbon" },
    { value: "steel", label: "Steel" },
    { value: "wood", label: "Wood" },
  ];

  const colorOptions = [
    { value: "black", label: "black" },
    { value: "blue", label: "blue" },
    { value: "gray", label: "gray" },
    { value: "pink", label: "pink" },
    { value: "red", label: "red" },
    { value: "white", label: "white" },
  ];
  const auth = useAuthUser();
  const navigate = useNavigate();

  const [joinCampaignResult, setJoinCampaignResult] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function joinCampaign() {
    axios.post(apiUrlInfluencers + "/join-campaign", { campaignId: campaignDetail.id })
      .then((response) => {
        if (response.data.isSuccessful) {
          setJoinCampaignResult({
            message: response.data.message,
            data: response.data.data,
            detailMessage: response.data.detailMessage,
            joinId: response.data.createdAggregationRootId
          });
          handleOpen();
        }
      });
  }

  function showLinks() {
    setJoinCampaignResult({
      message: "Your unique links for this campaign are listed in below. You can copy and share on related platforms.",
      data: campaignDetail.joinedCampaign.influencerJoinedCampaignUrls
    });

    handleOpen();
  }

  const [activateJoinResult, setActivateJoinResult] = useState(null);
  function activeJoin() {
    axios.post(apiUrlInfluencers + "/activate-campaign-joining", { joinId: joinCampaignResult.joinId })
      .then((response) => {
        setActivateJoinResult(response.data);
        toggleSnackbar();
        handleClose();
      }
      );
  }

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => {
    if (!show) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  };





  return (
    <>
      <SoftBox>
        <SoftBox display="flex" justifyContent="flex-end">
          <ReactIf condition={auth.userType == 2}>
            <SoftButton variant="outlined" color="error" iconOnly>
              <Icon>favorite</Icon>
            </SoftButton>
            <SoftBox ml={1}>
              <ReactIf condition={campaignDetail.joinedCampaign == null}>
                <SoftButton variant="gradient" color="info" onClick={joinCampaign}>
                  JOIN CAMPAIGN
                </SoftButton>
              </ReactIf>
              <ReactIf condition={campaignDetail.joinedCampaign != null}>
                <SoftButton variant="gradient" color="primary" onClick={showLinks}>
                  YOU HAVE JOINED, GET YOUR LINKS
                </SoftButton>
              </ReactIf>
            </SoftBox>
          </ReactIf>
          <ReactIf condition={auth.userType == 3}>
            <SoftButton variant="gradient" color="info" onClick={() => {
              navigate("/advertiser/campaigns/edit?id=" + campaignDetail.id);
            }}>
              EDIT CAMPAIGN
            </SoftButton>
          </ReactIf>
        </SoftBox>

        <SoftBox mb={1}>
          <SoftTypography variant="h3" fontWeight="bold">
            {campaignDetail.campaignName}

          </SoftTypography>


        </SoftBox>
        {/* <SoftTypography variant="h4" color="text">
        <Icon>star</Icon>
        <Icon>star</Icon>
        <Icon>star</Icon>
        <Icon>star</Icon>
        <Icon>star_half</Icon>
      </SoftTypography> */}
        <Grid container item>
          <Grid container item xs={6}>
            <SoftBox>
              <SoftBox mt={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Cpc Amount
                </SoftTypography>

              </SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  ${campaignDetail.cpcAmount}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid container item xs={6}>
            <SoftBox>
              <SoftBox mt={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Cpi Amount
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  ${campaignDetail.cpiAmount}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>





        <SoftBadge variant="contained" color="success" badgeContent="available" container />
        <SoftBox mt={3} mb={1} ml={0.5}>
          <SoftTypography variant="caption" fontWeight="bold">
            Description
          </SoftTypography>
        </SoftBox>
        <SoftBox m={0} pl={4} mb={2}>
          {campaignDetail.description}
        </SoftBox>
        <SoftBox mt={3}>
          <Grid container spacing={3}>
            <ReactIf condition={(campaignDetail.googlePlayAppUrl ?? "") != ""}>
              <Grid item xs={12} lg={6}>
                <SoftButton variant="outlined" color="secondary" fullWidth onClick={() => {

                  window.open(campaignDetail.googlePlayAppUrl);
                }}>
                  GOOGLE PLAY
                </SoftButton>
              </Grid>
            </ReactIf>

            <ReactIf condition={(campaignDetail.appStoreAppUrl ?? "") != ""}>
              <Grid item xs={12} lg={6}>
                <SoftButton variant="outlined" color="secondary" fullWidth onClick={() => {
                  window.open(campaignDetail.appStoreAppUrl);
                }}>
                  APP STORE
                </SoftButton>
              </Grid>
            </ReactIf>

            <ReactIf condition={(campaignDetail.webLinkUrl ?? "") != ""}>
              <Grid item xs={12} lg={6}>
                <SoftButton variant="outlined" color="secondary" fullWidth onClick={() => {
                  window.open(campaignDetail.webLinkUrl);
                }}>
                  OTHER RESOURCES FOR CAMPAIGN
                </SoftButton>
              </Grid>
            </ReactIf>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <JoinedCampaignUrls joinCampaignResult={joinCampaignResult}></JoinedCampaignUrls>


          <SoftBox display="flex" justifyContent="flex-end" mt={3}>
            <SoftBox mr={1}>
              <SoftButton color="light" onClick={handleClose}>{campaignDetail.joinedCampaign==null?"CANCEL":"CLOSE"}</SoftButton>
            </SoftBox>
            <ReactIf condition={campaignDetail.joinedCampaign == null}>
              <SoftButton variant="gradient" color="info" onClick={() => {
                activeJoin();
              }}>
                START
              </SoftButton>
            </ReactIf>
          </SoftBox>


        </Box>
      </Modal>


      <SoftSnackbar
        color={activateJoinResult?.isSuccessful ? "info" : "danger"}
        icon="notifications"
        title={activateJoinResult?.message}
        content={activateJoinResult?.detailMessage}
        dateTime=""
        open={show}
        close={toggleSnackbar}
      />
    </>
  );
}

export default ProductInfo;
