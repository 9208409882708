import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

// Data
import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
import axios from "axios";
import { func } from "prop-types";
import { Skeleton } from "@mui/material";
import ReactIf from "reactif";
import { indicators, metrics, dateRanges, platforms, apiUrlTracking,apiUrlCampaigns } from "commonData";

import SocialItem from "layouts/applications/analytics/components/SocialItem";
import ReactCohortGraph from "react-cohort-graph";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function Cohort() {
  const [apps, setApps] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  function getLookups() {
    axios.get(apiUrlCampaigns + "/select-list")
      .then((response) => {
        setCampaigns(response.data.map(function (m) {
          return { value: m.id, label: m.text };
        }));
      });
    axios.get(apiUrlCampaigns + "/apps/select-list")
      .then((response) => {
        setApps(response.data.map(function (m) {
          return { value: m.id, label: m.text };
        }));
      });
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    'border-radius': '10px',
    'border-color': '#ccc'
  };

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = function (event, newValue) {
    // if (onPeriodChanged)
    //   onPeriodChanged(newValue);
    setTabValue(newValue);
  }
  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >

      {dateRanges.filter(f => f.value != "Custom").map((item, index) => (
        <MenuItem key={index} onClick={event => {
          handleInputChange({ name: "dateRange", value: item.value });
          closeMenu();
        }}>{item.label}</MenuItem>
      ))}
    </Menu>
  );



  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { values } = breakpoints;
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  let selectedCampaigns = () => {
    var x = (campaigns.length == overviewQuery.campaigns.length ?
      [] :
      campaigns.filter(f => overviewQuery.campaigns.filter(f2 => f2 == f.value).length > 0));
    return x;
  }

  let selectedApps = () => {
    var x = (apps.length == overviewQuery.apps.length ?
      [] :
      apps.filter(f => overviewQuery.apps.filter(f2 => f2 == f.value).length > 0));
    return x;
  }

  let selectedPlatforms = () => {
    var x = (platforms.length == overviewQuery.apps.length ?
      [] :
      apps.filter(f => overviewQuery.platforms.filter(f2 => f2 == f.value).length > 0));
    return x;
  }



  const [overviewQueryFormData, setOverviewQueryFormData] = useState(
    {
      "dateRange": "Today",
      "beginDate": null,
      "endDate": null,
      "campaigns": campaigns.map(m => m.value),
      "apps": apps.map(m => m.value),
      "platforms": platforms.map(m => m.value)
    });

  const [overviewQuery, setOverviewQuery] = useState(
    {
      "dateRange": "Today",
      "beginDate": null,
      "endDate": null,
      "campaigns": campaigns.map(m => m.value),
      "apps": apps.map(m => m.value),
      "platforms": platforms.map(m => m.value),
      "showPeriod": "Hourly"
    });



  useEffect(() => {
    getLookups();
  }, []);

  const DATA = {
    days: {
      "05-22-2016": [200, 180, 120, 80, 50, 40, 30, 20, 50, 40, 23, 43, 20, 34, 30, 20, 10, 5],
      "05-23-2016": [300, 180, 120, 80, 50, 20, 15, 10, 5, 5, 5, 40, 23, 15, 10, 5, 1],
      "05-24-2016": [200, 180, 120, 80, 50, 20, 15, 10, 5, 5, 5, 4, 2, 1, 4, 5],
      "05-25-2016": [200, 180, 120, 80, 50, 20, 15, 10, 5, 5, 5, 3, 3, 2, 1],
      "05-26-2016": [300, 180, 120, 80, 50, 20, 15, 10, 5, 5, 5, 5, 3, 1],
      "05-27-2016": [200, 180, 120, 80, 50, 20, 15, 10, 5, 5, 5, 3, 2],
      "05-28-2016": [300, 180, 120, 80, 50, 20, 15, 10, 5, 5, 5, 2],
      "05-29-2016": [200, 180, 120, 80, 50, 20, 15, 10, 5, 5, 5],
      "06-30-2016": [300, 180, 120, 80, 50, 20, 15, 10, 5, 2],
      "06-01-2016": [200, 180, 120, 80, 50, 20, 10, 5, 1],
      "06-02-2016": [300, 180, 120, 80, 50, 20, 10, 5],
      "06-03-2016": [200, 120, 80, 30, 20, 5, 1],
      "06-04-2016": [200, 80, 30, 10, 5, 3],
      "06-05-2016": [300, 180, 100, 80, 70],
      "06-06-2016": [200, 120, 80, 40],
      "06-07-2016": [200, 80, 30],
      "06-08-2016": [300, 180],
      "06-09-2016": [200]
    },
    weeks: {
      "week1": [200, 10, 20],
      "week2": [300, 200],
      "week3": [200]
    },
    months: {
      "month1": [200, 10, 20, 30],
      "month2": [300, 200, 150],
      "month3": [200, 110],
      "month4": [100]
    }
  };

  const handleFormInputChange = (e) => {
    setOverviewQueryFormData({
      ...overviewQueryFormData,
      [e.name]: e.value,
    });
  };

  const handleInputChange = (e) => {
    setOverviewQuery({
      ...overviewQuery,
      [e.name]: e.value,
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <SoftBox mb={0} p={1}>
              <SoftTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                Cohort Analysis
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SoftBox display="flex" justifyContent="flex-end" mb={0} mt={1} p={1}>
              {selectedCampaigns().map((item, index) => (
                <SoftBox mr={1}><SoftButton onClick={event => {
                  handleFormInputChange({ name: "campaigns", value: overviewQuery.campaigns.filter(f => f != item.value) });
                  setOverviewQuery({
                    ...overviewQuery,
                    ["campaigns"]: overviewQuery.campaigns.filter(f => f != item.value)
                  });
                }}>{item.label}<Icon>cancel</Icon></SoftButton></SoftBox>
              ))}
              {selectedApps().map((item, index) => (
                <SoftBox mr={1}><SoftButton onClick={event => {
                  handleFormInputChange({ name: "apps", value: overviewQuery.apps.filter(f => f != item.value) });
                  setOverviewQuery({
                    ...overviewQuery,
                    ["apps"]: overviewQuery.apps.filter(f => f != item.value)
                  });
                }}>{item.label}<Icon>cancel</Icon></SoftButton></SoftBox>
              ))}
              {selectedPlatforms().map((item, index) => (
                <SoftBox mr={1}><SoftButton onClick={event => {
                  handleFormInputChange({ name: "platforms", value: overviewQuery.platforms.filter(f => f != item.value) });
                  setOverviewQuery({
                    ...overviewQuery,
                    ["platforms"]: overviewQuery.platforms.filter(f => f != item.value)
                  });
                }}>{item.label}<Icon>cancel</Icon></SoftButton></SoftBox>
              ))}
              <SoftBox mr={3}>
                <SoftButton variant="outlined" color="secondary" onClick={handleOpen}>
                  FILTERS&nbsp;&nbsp;
                  <Icon>tune</Icon>
                </SoftButton>
              </SoftBox>


              <SoftButton variant="gradient" color="dark" onClick={openMenu}>
                {dateRanges.filter(f => f.value == overviewQuery.dateRange)[0].label}&nbsp;
                <Icon>expand_more</Icon>
              </SoftButton>
              {renderMenu}
            </SoftBox>
          </Grid>

        </Grid>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <ReactIf condition={true}>


            </ReactIf>
            <ReactIf condition={false}>
              <Skeleton variant="rounded" height={84}></Skeleton>
            </ReactIf>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ height: "100%" }}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>

                <Grid container sm={12}>
                  <Grid item sm={12} md={9}>
                    <SoftBox mb={1}>
                      <SoftTypography variant="h6">Cohort Analysis by Selected Filters</SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <AppBar position="static">
                      
                      <Tabs
                        orientation={tabsOrientation}
                        value={tabValue}
                        onChange={handleSetTabValue}
                        sx={{ background: "transparent" }}
                      >
                        <Tab label="Hourly" />
                        <Tab label="Daily" />
                        <Tab label="Weekly" />
                      </Tabs>
                    </AppBar>
                  </Grid>
                </Grid>

                <Tooltip title="See your ad performance by metrics" placement="bottom">
                  <SoftButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>priority_high</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
              <SoftBox p={2}>
                <ReactCohortGraph
                  data={DATA}
                  showHeaderValues
                /></SoftBox>
            </Card>
          </Grid>

        </Grid>
      </SoftBox>
      <Footer />
      <div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <SoftTypography id="modal-modal-title" variant="h6" component="h2">
              Filter & Parameters
            </SoftTypography>
            <SoftBox mt={1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Date Range
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect

                    options={dateRanges}
                    value={{ label: (dateRanges.filter(f => f.value == overviewQueryFormData.dateRange)[0]?.label), value: overviewQueryFormData.dateRange }}
                    onChange={event => {
                      handleFormInputChange({ name: "dateRange", value: event.value });
                    }}
                  />

                  <Grid container spacing={3} display={overviewQueryFormData.dateRange == "Custom" ? "" : "none"}>
                    <Grid item xs={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Begin Date
                          </SoftTypography>
                        </SoftBox>
                        <SoftDatePicker value={overviewQueryFormData.beginDate}
                          onChange={selectedDate => {
                            handleFormInputChange({ name: "beginDate", value: selectedDate[0] });
                          }}
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            End Date
                          </SoftTypography>
                        </SoftBox>
                        <SoftDatePicker value={overviewQueryFormData.endDate}
                          onChange={selectedDate => {
                            handleFormInputChange({ name: "endDate", value: selectedDate[0] });
                          }} />
                      </SoftBox>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Campaigns
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        options={campaigns}
                        isMulti
                        size="large"
                        value={overviewQueryFormData.campaigns.map(function (item) {
                          return { value: item, label: campaigns.filter(f => f.value == item)[0]?.label }
                        })}
                        onChange={event => {
                          handleFormInputChange({ name: "campaigns", value: event.map(item => item.value) });
                        }}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Apps
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        options={apps}
                        isMulti
                        size="large"
                        value={overviewQueryFormData.apps.map(function (item) {
                          return { value: item, label: apps.filter(f => f.value == item)[0]?.label }
                        })}
                        onChange={event => {
                          handleFormInputChange({ name: "apps", value: event.map(item => item.value) });
                        }}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Platform & Channels
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        options={platforms}
                        isMulti
                        size="large"
                        value={overviewQueryFormData.platforms.map(function (item) {
                          return { value: item, label: platforms.filter(f => f.value == item)[0]?.label }
                        })}
                        onChange={event => {
                          handleFormInputChange({ name: "platforms", value: event.map(item => item.value) });
                        }}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>

              </Grid>
            </SoftBox>

            <SoftBox display="flex" justifyContent="flex-end" mt={3}>
              <SoftBox mr={1}>
                <SoftButton color="light" onClick={handleClose}>CANCEL</SoftButton>
              </SoftBox>
              <SoftButton variant="gradient" color="info" onClick={() => {
                setOverviewQuery({
                  ...overviewQuery,
                  ["dateRange"]: overviewQueryFormData.dateRange,
                  ["beginDate"]: overviewQueryFormData.beginDate,
                  ["endDate"]: overviewQueryFormData.endDate,
                  ["campaigns"]: overviewQueryFormData.campaigns,
                  ["apps"]: overviewQueryFormData.apps,
                  ["platforms"]: overviewQueryFormData.platforms
                });
                
                handleClose();
              }}>
                FILTER
              </SoftButton>
            </SoftBox>
          </Box>
        </Modal>
      </div>
    </DashboardLayout>
  );
}

export default Cohort;