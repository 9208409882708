/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import TeamProfileCard from "examples/Cards/TeamCards/TeamProfileCard";
import EventCard from "examples/Cards/EventCard";

// Teams page components
import Header from "./components/Header";
import Stories from "./components/Stories";
import Post from "./components/Post";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoInvision from "assets/images/small-logos/logo-invision.svg";
import WeatherCard from "examples/Cards/WeatherCard";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";

import miniGradientLineChartData from "layouts/dashboards/crm/data/miniGradientLineChartData";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { apiUrlInfluencers } from "commonData";
import { platforms } from "commonData";

function InfluencerDetail() {
  const navigate=useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { visitorsChart, incomeChart } = miniGradientLineChartData;
  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("6 May - 7 May");
  const [customersDropdownValue, setCustomersDropdownValue] = useState("6 May - 7 May");
  const [revenueDropdownValue, setRevenueDropdownValue] = useState("6 May - 7 May");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }) => setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };


  // TeamProfileCard dropdown menu state
  const [marketingMenu, setMarketingMenu] = useState(null);
  const [designMenu, setDesignMenu] = useState(null);

  // TeamProfileCard dropdown menu handlers
  const openMarketingMenu = (event) => setMarketingMenu(event.currentTarget);
  const closeMarketingMenu = () => setMarketingMenu(null);
  const openDesignMenu = (event) => setDesignMenu(event.currentTarget);
  const closeDesignMenu = () => setDesignMenu(null);

  // Dropdown menu for the digital marketing TeamProfileCard
  const renderMarketingMenu = (
    <Menu
      anchorEl={marketingMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(marketingMenu)}
      onClose={closeMarketingMenu}
      keepMounted
    >
      <MenuItem onClick={closeMarketingMenu}>Action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Something else here</MenuItem>
    </Menu>
  );

  // Dropdown menu for the design TeamProfileCard
  const renderDesignMenu = (
    <Menu
      anchorEl={designMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(designMenu)}
      onClose={closeDesignMenu}
      keepMounted
    >
      <MenuItem onClick={closeDesignMenu}>Action</MenuItem>
      <MenuItem onClick={closeDesignMenu}>Another action</MenuItem>
      <MenuItem onClick={closeDesignMenu}>Something else here</MenuItem>
    </Menu>
  );

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );


  const [influencerDetail, setInfluencerDetail] = useState(null);

  useEffect(() => {
    
    axios.post(apiUrlInfluencers + "/influencer-detail", { id: searchParams.get("id") })
      .then((response) => {
        setInfluencerDetail(response.data.data);


        //         fullName:"Hande Erçel"
        // id: "efd046c1-e5f6-4833-af62-8ffd6baef679"
        // influencerStatusId: "0"
        // otherPlatformIdAndUserNameList: []
        // platformId: 1
        // profilePicturePath: "path"
        // statistics: {totalMediaUploads: 4, followerCount: 1, followingCount: 1, engagementRate: 60}
        // username: "handemiyy"


      });
  }, []);

  function platformChanged(id){
    navigate("/advertiser/influencers/detail?id=" +id);
    axios.post(apiUrlInfluencers + "/influencer-detail", { id: id })
    .then((response) => {
      setInfluencerDetail(response.data.data);      
    });
  }

  return (
    <DashboardLayout>
      <Header influencerDetail={influencerDetail} platformChanged={platformChanged} />
      {/* <SoftBox my={3}>
        <Stories />
      </SoftBox> */}
      {influencerDetail == null ? <></> :
        <SoftBox my={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <DefaultCounterCard
                  count={influencerDetail.statistics.posts}
                  title="Media Uploads"
                  description="Total"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DefaultCounterCard
                  count={1}
                  suffix="m"
                  title="Follower"
                  description={influencerDetail.statistics.followerCount}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DefaultCounterCard
                  count={influencerDetail.statistics.followingCount}
                  suffix=""
                  title="Following"
                  description={influencerDetail.statistics.followingCount}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DefaultCounterCard
                  count={influencerDetail.statistics.engagementRate}
                  suffix="%"
                  title="Engagement Rate"
                  description={"%" + influencerDetail.statistics.engagementRate??""}
                />
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      }


      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <DefaultStatisticsCard
              title="Followers for the last 30 days"
              count="5k"
              percentage={{
                color: "success",
                value: "+55%",
                label: "since last 30 days",
              }}
              dropdown={{
                action: openSalesDropdown,
                menu: renderMenu(salesDropdown, closeSalesDropdown),
                value: salesDropdownValue,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DefaultStatisticsCard
              title="Following for the last 30 days"
              count="30"
              percentage={{
                color: "success",
                value: "+12%",
                label: "since last 30 days",
              }}
              dropdown={{
                action: openCustomersDropdown,
                menu: renderMenu(customersDropdown, closeCustomersDropdown),
                value: customersDropdownValue,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DefaultStatisticsCard
              title="Media for the last 30 days"
              count="39"
              percentage={{
                color: "secondary",
                value: "+3",
                label: "since last 30 days",
              }}
              dropdown={{
                action: openRevenueDropdown,
                menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                value: revenueDropdownValue,
              }}
            />
          </Grid>
        </Grid>
      </SoftBox>

      <SoftBox mb={3}>


        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <MiniGradientLineChart
              title="Followers by month"
              description={
                <SoftTypography variant="h5" fontWeight="bold">
                  5,927{" "}
                  <SoftTypography variant="button" color="success" fontWeight="bold">
                    +55%
                  </SoftTypography>
                </SoftTypography>
              }
              chart={visitorsChart}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MiniGradientLineChart
              title="Following by month"
              description={
                <SoftTypography variant="h5" fontWeight="bold">
                  130,832{" "}
                  <SoftTypography variant="button" color="success" fontWeight="bold">
                    +90%
                  </SoftTypography>
                </SoftTypography>
              }
              chart={incomeChart}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MiniGradientLineChart
              title="Media uploads by month"
              description={
                <SoftTypography variant="h5" fontWeight="bold">
                  $130,832{" "}
                  <SoftTypography variant="button" color="success" fontWeight="bold">
                    +90%
                  </SoftTypography>
                </SoftTypography>
              }
              chart={incomeChart}
            />
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InfluencerDetail;
