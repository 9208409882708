// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import ReactIf from "reactif";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Card, Modal } from "@mui/material";
import { apiUrlInfluencers } from "commonData";
import { platforms } from "commonData";
import SoftAvatar from "components/SoftAvatar";

function JoinedCampaignUrlItem({ urlData }) {

    const [copied, setCopied] = useState(false);
    return (
        <>
            <SoftBox key={""} component="li" display="flex" alignItems="center" py={1} mb={1}>
                <SoftBox mr={2}>
                    <SoftAvatar src={platforms.filter(f => f.value == urlData.platformId)[0].logo} alt="something here" variant="rounded" shadow="md" />
                </SoftBox>
                <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    <SoftTypography variant="button" fontWeight="medium">
                        http://test.nords.ai/{urlData.urlPath}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text">
                        {platforms.filter(f => f.value == urlData.platformId)[0].label}
                    </SoftTypography>
                </SoftBox>
                <SoftBox ml="auto">
                    {(<SoftButton variant="text" color={copied ? "primary" : "info"} onClick={() => {
                        navigator.clipboard.writeText("http://test.nords.ai/" + urlData.urlPath);
                        setCopied(true);
                        setTimeout(() => {
                            setCopied(false);
                        }, 10000);
                    }}>{copied ? "COPIED" : "COPY"}</SoftButton>)}
                </SoftBox>
            </SoftBox>
        </>
    );
}

export default JoinedCampaignUrlItem;
