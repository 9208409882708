import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MixedChart from "examples/Charts/MixedChart";
// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";


// Data
import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
import axios from "axios";
import { func } from "prop-types";
import { Skeleton } from "@mui/material";
import ReactIf from "reactif";
import { indicators, metrics, dateRanges, campaigns, apps, platforms, apiUrlTracking , apiUrlCampaigns} from "commonData";


function Fraud() {
  const [apps, setApps] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  function getLookups() {
    axios.get(apiUrlCampaigns + "/select-list")
      .then((response) => {
        setCampaigns(response.data.map(function (m) {
          return { value: m.id, label: m.text };
        }));
      });
    axios.get(apiUrlCampaigns + "/apps/select-list")
      .then((response) => {
        setApps(response.data.map(function (m) {
          return { value: m.id, label: m.text };
        }));
      });
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    'border-radius': '10px',
    'border-color': '#ccc'
  };

  const { values } = breakpoints;
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >

      {dateRanges.filter(f => f.value != "Custom").map((item, index) => (
        <MenuItem key={index} onClick={event => {
          handleInputChange({ name: "dateRange", value: item.value });
          closeMenu();
        }}>{item.label}</MenuItem>
      ))}
    </Menu>
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [fraudChDataFetched, SetFraudChDataFetched] = useState(false);
  const [fraudChData, SetFraudChData] = useState({
    labels: [],
    datasets: []
  });

  let selectedCampaigns = () => {
    var x = (campaigns.length == fraudQuery.campaigns.length ?
      [] :
      campaigns.filter(f => fraudQuery.campaigns.filter(f2 => f2 == f.value).length > 0));
    return x;
  }

  let selectedApps = () => {
    var x = (apps.length == fraudQuery.apps.length ?
      [] :
      apps.filter(f => fraudQuery.apps.filter(f2 => f2 == f.value).length > 0));
    return x;
  }

  let selectedPlatforms = () => {
    var x = (platforms.length == fraudQuery.apps.length ?
      [] :
      apps.filter(f => fraudQuery.platforms.filter(f2 => f2 == f.value).length > 0));
    return x;
  }

  const [fraudQueryFormData, setFraudQueryFormData] = useState(
    {
      "dateRange": "Today",
      "beginDate": null,
      "endDate": null,
      "campaigns": campaigns.map(m => m.value),
      "apps": apps.map(m => m.value),
      "platforms": platforms.map(m => m.value)
    });

  const [fraudQuery, setFraudQuery] = useState(
    {
      "dateRange": "Today",
      "beginDate": null,
      "endDate": null,
      "campaigns": campaigns.map(m => m.value),
      "apps": apps.map(m => m.value),
      "platforms": platforms.map(m => m.value),
      "showPeriod": "Hourly"
    });


  useEffect(() => {
    getLookups();
    getFraudData();
  }, [fraudQuery]);

  function getFraudData() {
    if (fraudQuery.dateRange != "Custom" || (fraudQuery.beginDate && fraudQuery.endDate))
      axios.post(apiUrlTracking + "/Home/get-frauds", fraudQuery)
        .then((response) => {
          SetFraudChDataFetched(true);
          SetFraudChData(response.data);
        });
  }

  const handleFormInputChange = (e) => {
    setFraudQueryFormData({
      ...fraudQueryFormData,
      [e.name]: e.value,
    });
  };

  const handleInputChange = (e) => {
    setFraudQuery({
      ...fraudQuery,
      [e.name]: e.value,
    });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <SoftBox mb={0} p={1}>
              <SoftTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                Fraud Detections
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SoftBox display="flex" justifyContent="flex-end" mb={0} mt={1} p={1}>
              {selectedCampaigns().map((item, index) => (
                <SoftBox mr={1}><SoftButton onClick={event => {
                  handleFormInputChange({ name: "campaigns", value: fraudQuery.campaigns.filter(f => f != item.value) });
                  setFraudQuery({
                    ...fraudQuery,
                    ["campaigns"]: fraudQuery.campaigns.filter(f => f != item.value)
                  });
                }}>{item.label}<Icon>cancel</Icon></SoftButton></SoftBox>
              ))}
              {selectedApps().map((item, index) => (
                <SoftBox mr={1}><SoftButton onClick={event => {
                  handleFormInputChange({ name: "apps", value: fraudQuery.apps.filter(f => f != item.value) });
                  setFraudQuery({
                    ...fraudQuery,
                    ["apps"]: fraudQuery.apps.filter(f => f != item.value)
                  });
                }}>{item.label}<Icon>cancel</Icon></SoftButton></SoftBox>
              ))}
              {selectedPlatforms().map((item, index) => (
                <SoftBox mr={1}><SoftButton onClick={event => {
                  handleFormInputChange({ name: "platforms", value: fraudQuery.platforms.filter(f => f != item.value) });
                  setFraudQuery({
                    ...fraudQuery,
                    ["platforms"]: fraudQuery.platforms.filter(f => f != item.value)
                  });
                }}>{item.label}<Icon>cancel</Icon></SoftButton></SoftBox>
              ))}
              <SoftBox mr={3}>
                <SoftButton variant="outlined" color="secondary" onClick={handleOpen}>
                  FILTERS&nbsp;&nbsp;
                  <Icon>tune</Icon>
                </SoftButton>
              </SoftBox>


              <SoftButton variant="gradient" color="dark" onClick={openMenu}>
                {dateRanges.filter(f => f.value == fraudQuery.dateRange)[0].label}&nbsp;
                <Icon>expand_more</Icon>
              </SoftButton>
              {renderMenu}
            </SoftBox>
          </Grid>

        </Grid>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <ReactIf condition={fraudChDataFetched}>
              <MixedChart title="Detected fraud rates to total impressions" chart={fraudChData} height="24rem"
                onPeriodChanged={function (newValue) {
                  var period = "";
                  if (newValue == 0)//hourly
                    period = "Hourly";
                  else if (newValue == 1)//daily
                    period = "Daily";
                  else if (newValue == 2)//weekly  
                    period = "Weekly";
                  handleInputChange({ name: "showPeriod", value: period });
                }} />
            </ReactIf>
            <ReactIf condition={!fraudChDataFetched}>
              <Skeleton variant="rounded" height={84}></Skeleton>
            </ReactIf>
          </Grid>
        </Grid>
      </SoftBox>

      <Footer />
      <div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <SoftTypography id="modal-modal-title" variant="h6" component="h2">
              Filter & Parameters
            </SoftTypography>
            <SoftBox mt={1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Date Range
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect

                    options={dateRanges}
                    value={{ label: (dateRanges.filter(f => f.value == fraudQueryFormData.dateRange)[0]?.label), value: fraudQueryFormData.dateRange }}
                    onChange={event => {
                      handleFormInputChange({ name: "dateRange", value: event.value });
                    }}
                  />

                  <Grid container spacing={3} display={fraudQueryFormData.dateRange == "Custom" ? "" : "none"}>
                    <Grid item xs={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Begin Date
                          </SoftTypography>
                        </SoftBox>
                        <SoftDatePicker value={fraudQueryFormData.beginDate}
                          onChange={selectedDate => {
                            handleFormInputChange({ name: "beginDate", value: selectedDate[0] });
                          }}
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            End Date
                          </SoftTypography>
                        </SoftBox>
                        <SoftDatePicker value={fraudQueryFormData.endDate}
                          onChange={selectedDate => {
                            handleFormInputChange({ name: "endDate", value: selectedDate[0] });
                          }} />
                      </SoftBox>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Campaigns
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        options={campaigns}
                        isMulti
                        size="large"
                        value={fraudQueryFormData.campaigns.map(function (item) {
                          return { value: item, label: campaigns.filter(f => f.value == item)[0]?.label }
                        })}
                        onChange={event => {
                          handleFormInputChange({ name: "campaigns", value: event.map(item => item.value) });
                        }}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Apps
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        options={apps}
                        isMulti
                        size="large"
                        value={fraudQueryFormData.apps.map(function (item) {
                          return { value: item, label: apps.filter(f => f.value == item)[0]?.label }
                        })}
                        onChange={event => {
                          handleFormInputChange({ name: "apps", value: event.map(item => item.value) });
                        }}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Platform & Channels
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        options={platforms}
                        isMulti
                        size="large"
                        value={fraudQueryFormData.platforms.map(function (item) {
                          return { value: item, label: platforms.filter(f => f.value == item)[0]?.label }
                        })}
                        onChange={event => {
                          handleFormInputChange({ name: "platforms", value: event.map(item => item.value) });
                        }}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>

              </Grid>
            </SoftBox>

            <SoftBox display="flex" justifyContent="flex-end" mt={3}>
              <SoftBox mr={1}>
                <SoftButton color="light" onClick={handleClose}>CANCEL</SoftButton>
              </SoftBox>
              <SoftButton variant="gradient" color="info" onClick={() => {
                setFraudQuery({
                  ...fraudQuery,
                  ["dateRange"]: fraudQueryFormData.dateRange,
                  ["beginDate"]: fraudQueryFormData.beginDate,
                  ["endDate"]: fraudQueryFormData.endDate,
                  ["campaigns"]: fraudQueryFormData.campaigns,
                  ["apps"]: fraudQueryFormData.apps,
                  ["platforms"]: fraudQueryFormData.platforms
                });

                handleClose();
              }}>
                FILTER
              </SoftButton>
            </SoftBox>
          </Box>
        </Modal>
      </div>
    </DashboardLayout>
  );
}

export default Fraud;