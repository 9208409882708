/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";

import createStore from 'react-auth-kit/createStore';
import AuthProvider from "react-auth-kit/AuthProvider";
import axios from "axios";

const root = ReactDOM.createRoot(document.getElementById("root"));


const store = createStore({
  authName: '_auth',
  authType: 'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
});

if (store.tokenObject.authValue.auth)
  axios.defaults.headers.common['Authorization'] = store.tokenObject.authValue.auth.type + " " + store.tokenObject.authValue.auth.token;
root.render(
  <AuthProvider store={store}>
    <BrowserRouter>
      <SoftUIControllerProvider>
        <App />
      </SoftUIControllerProvider>
    </BrowserRouter>
  </AuthProvider>
);
