/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./components/DataTable/index";

// Data
import dataTableData from "./data/dataTableData";
import Table from "examples/Tables/Table";
import { indicators, metrics, dateRanges, campaigns, apps, platforms, apiUrlTracking, apiUrlInfluencers } from "commonData";
import { Slider } from "@mui/material";
import InfluencerSearchBar from "./components/InfluencerSearchBar";
import axios from "axios";
import ProductCell from "./components/ProductCell";
import ActionCell from "layouts/ecommerce/products/products-list/components/ActionCell";

function InfluencerFavorites() {
  const [listData, setListData] = useState({
    columns: [
      {
        Header: "INFLUENCER",
        accessor: "influencer",
        Cell: ({ value: [data] }) => (
          <Link to={"/advertiser/influencers/detail?id=" + data.id}>
            <ProductCell image={data.image} name={data.name} username={data.username} checked={data.checked} />
          </Link>
        ),
      },
      { Header: "followers", accessor: "followers" },
      { Header: "interactions", accessor: "interactions" },
      { Header: "action", accessor: "action" },
    ],
    rows: []
  });

  const [value, setValue] = useState([4, 8]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [influencerAgeValue, setInfluencerAgeValue] = useState([21, 38]);

  const handleInfluencerAgeValueChange = (event, newValue) => {
    setInfluencerAgeValue(newValue);
  };
  function influencerAgeValueText(value) {
    return value;
  }

  function onSearchClicked(filterData) {
    axios.post(apiUrlInfluencers + "/influencers", filterData)
      .then((response) => {
        var list = response.data.data.map(function (item) {
          return {
            influencer: [{
              id: item.id,
              name: item.fullName,
              username: item.username,
              image: item.profilePicturePath,
              checked: false
            }],
            followers: item.statistics.followerCount,
            interactions: item.statistics.followingCount,
            action: <ActionCell />
          };
        });
        setListData({
          ...listData,
          ["rows"]: list,
        });

      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox>
        <Card sx={{ overflow: "visible" }}>
          <Grid>
            <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
              <SoftBox lineHeight={1}>
                {/* <SoftTypography variant="h5" fontWeight="medium">
                  Influencers
                </SoftTypography> */}
                <SoftTypography variant="subtitle2" fontWeight="regular" color="text">
                  Your Favorite Influencers
                </SoftTypography>
              </SoftBox>
              {/* <Stack spacing={1} direction="row">
                <SoftButton variant={open ? "outlined" : "gradient"} color={open ? "secondary" : "info"} size="small" onClick={handleOpen}>
                  FILTERS<Icon>tune</Icon>
                </SoftButton>
              </Stack> */}
            </SoftBox>
          </Grid>
        </Card>

        <Grid mt={1}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
              <Grid container>
                {/* <Grid container item xs={12}>

                  <SoftButton variant="outlined" color="info" size="small">
                    SAVE<Icon>bookmark</Icon>
                  </SoftButton>
                </Grid> */}

                <Grid container item xs={12}>
                  <DataTable
                    table={listData}
                    entriesPerPage={{
                      defaultValue: 7,
                      entries: [10, 25, 50, 100],
                    }}
                    canSearch
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InfluencerFavorites;
