/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "../schemas/form";

const {
  formField: {
    campaignName,
    description,
    beginDate,
    endDate,
    campaignTargetId, 
    webLinkUrl, 
    appStoreAppUrl, 
    googlePlayAppUrl,
    fallbackUrl,
    campaignBudgetTypeId, 
    campaignBudgetCategoryId, 
    totalAmount, 
    cpiAmount, 
    cpcAmount
  },
} = checkout;

const initialValues = {
  [campaignName.name]:"",
  [description.name]:"",
  [beginDate.name]:"",
  [endDate.name]:"",
  [campaignTargetId.name]:1, 
  [webLinkUrl.name]:"",
  [appStoreAppUrl.name]:"", 
  [googlePlayAppUrl.name]:"",
  [fallbackUrl.name]:"",
  [campaignBudgetTypeId.name]:1,
  [campaignBudgetCategoryId.name]:"",
  [totalAmount.name]:"",
  [cpiAmount.name]:"",
  [cpcAmount.name]:""
};

export default initialValues;
