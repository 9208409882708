import { useState, useEffect } from "react";
// react-router-dom components

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard PRO React example components

// Data
import { indicators, metrics, dateRanges, campaigns, apps, platforms, genders, locations, interests, apiUrlTracking } from "commonData";
import { Button, Card, Slider } from "@mui/material";
import ReactIf from "reactif";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import SoftInput from "components/SoftInput";
import SoftTagInput from "components/SoftTagInput";
function InfluencerSearchBar({ onSearchClicked }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);
    const [filter, setFilter] = useState(
        {
            "searchOn": platforms[0],
            "followerRange": [4, 8],
            "influencerLocations": [],
            "audienceLocations": [],
            "influencerGenders": [],
            "audienceGenders": [],
            "influencerAgeRanges": [21, 38],
            "audienceAgeRanges": [21, 38],
            "influencerInterests": [],
            "audienceInterests": [],
            "hashtags": [],
            "mentions": []
        });

    const handleFormInputChange = (e) => {
        setFilter({
            ...filter,
            [e.name]: e.value,
        });
    };

    function followerRangeValueText(value) {
        return followerRangeMarks.filter(f => f.value == value)[0].label;
    }

    const followerRangeMarks = [
        {
            value: 1,
            label: '1k',
        },
        {
            value: 2,
            label: '5k',
        },
        {
            value: 3,
            label: '10k',
        },
        {
            value: 4,
            label: '30k',
        },
        {
            value: 5,
            label: '50k',
        },
        {
            value: 6,
            label: '75k',
        },
        {
            value: 7,
            label: '100k',
        },
        {
            value: 8,
            label: '150k',
        },
        {
            value: 9,
            label: '200k',
        },
        {
            value: 10,
            label: '300k',
        },
        {
            value: 11,
            label: '400k',
        },
        {
            value: 500,
            label: '500k',
        },
        {
            value: 12,
            label: '1m',
        },
        {
            value: 13,
            label: '1m+',
        },
    ];

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
        onSearchClicked(filter);
    }, []);

    return (
        <>
            <Card sx={{ overflow: "visible" }}>
                <Grid>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
                        <SoftBox lineHeight={1}>
                            {/* <SoftTypography variant="h5" fontWeight="medium">
                  Influencers
                </SoftTypography> */}
                            <SoftTypography variant="subtitle2" fontWeight="regular" color="text">
                                Search, List and Save Influencers
                            </SoftTypography>
                        </SoftBox>
                        <Stack spacing={1} direction="row">
                            <SoftButton variant={open ? "outlined" : "gradient"} color={open ? "secondary" : "info"} size="small" onClick={handleOpen}>
                                FILTERS<Icon>tune</Icon>
                            </SoftButton>
                        </Stack>
                    </SoftBox>
                </Grid>


                {/* <ReactIf condition={!open}>
                    <SoftBox pb={1} px={3}>
                        <Stack spacing={1} direction="row">
                            <SoftBox>
                                <SoftTypography variant="button" fontWeight="regular" color="text">Filters</SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <SoftButton>test <Icon>cancel</Icon></SoftButton>
                            </SoftBox>
                        </Stack>
                    </SoftBox>
                </ReactIf> */}



                <ReactIf condition={open}>
                    <SoftBox p={3}>
                        <SoftTypography variant="h6" component="h2">
                            Set your criteria to find the influencer that best suits your purpose
                        </SoftTypography>
                        <SoftBox mt={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Search on
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftSelect
                                                    options={platforms}
                                                    value={platforms.filter(f => f.value == filter.searchOn)[0]}
                                                    onChange={event => {
                                                        handleFormInputChange({ name: "searchOn", value: event });
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                    >
                                                        Follower Range
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Grid container justifyContent="center">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                    >
                                                        The influencer I'm looking for must have between <b>{followerRangeValueText(filter.followerRange[0])}</b> and <b>{followerRangeValueText(filter.followerRange[1])}</b> followers
                                                    </SoftTypography>
                                                </Grid>
                                                <Slider
                                                    getAriaLabel={() => 'Temperature range'}
                                                    value={filter.followerRange}
                                                    onChange={(event, newValue) => {
                                                        handleFormInputChange({ name: "followerRange", value: newValue });
                                                    }}
                                                    valueLabelDisplay="auto"
                                                    getAriaValueText={followerRangeValueText}
                                                    min={1}
                                                    max={13}
                                                    valueLabelFormat={followerRangeValueText}
                                                    size="small"
                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold" color="secondary">
                                        LOCATION
                                    </SoftTypography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Influencer's Location
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftSelect
                                                    options={locations}
                                                    isMulti
                                                    size="small"
                                                    value={filter.influencerLocations.map(function (item) {
                                                        return { value: item, label: locations.filter(f => f.value == item)[0]?.label }
                                                    })}
                                                    onChange={event => {
                                                        handleFormInputChange({ name: "influencerLocations", value: event.map(item => item.value) });
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Audience's Location
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftSelect
                                                    options={locations}
                                                    isMulti
                                                    size="small"
                                                    value={filter.audienceLocations.map(function (item) {
                                                        return { value: item, label: locations.filter(f => f.value == item)[0]?.label }
                                                    })}
                                                    onChange={event => {
                                                        handleFormInputChange({ name: "audienceLocations", value: event.map(item => item.value) });
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold" color="secondary">
                                        GENDER
                                    </SoftTypography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Influencer's Gender
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftSelect
                                                    options={genders}
                                                    isMulti
                                                    size="small"
                                                    value={filter.influencerGenders.map(function (item) {
                                                        return { value: item, label: genders.filter(f => f.value == item)[0]?.label }
                                                    })}
                                                    onChange={event => {
                                                        handleFormInputChange({ name: "influencerGenders", value: event.map(item => item.value) });
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Audience's Gender
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftSelect
                                                    options={genders}
                                                    isMulti
                                                    size="small"
                                                    value={filter.audienceGenders.map(function (item) {
                                                        return { value: item, label: genders.filter(f => f.value == item)[0]?.label }
                                                    })}
                                                    onChange={event => {
                                                        handleFormInputChange({ name: "audienceGenders", value: event.map(item => item.value) });
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>





                                <Grid item xs={12}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold" color="secondary">
                                        AGE
                                    </SoftTypography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="thin">
                                                        Influencer
                                                    </SoftTypography>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold" color="dark" ml={1}>
                                                        {filter.influencerAgeRanges[0]} and {filter.influencerAgeRanges[1]} ages
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Slider
                                                    value={filter.influencerAgeRanges}
                                                    onChange={(event, value) => {
                                                        handleFormInputChange({ name: "influencerAgeRanges", value: value });
                                                    }}
                                                    valueLabelDisplay="auto"
                                                    min={13}
                                                    max={99}
                                                    size="small"
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="thin">
                                                        Audience
                                                    </SoftTypography>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold" color="dark" ml={1}>
                                                        {filter.audienceAgeRanges[0]} and {filter.audienceAgeRanges[1]} ages
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Slider
                                                    value={filter.audienceAgeRanges}
                                                    onChange={(event, value) => {
                                                        handleFormInputChange({ name: "audienceAgeRanges", value: value });
                                                    }}
                                                    valueLabelDisplay="auto"
                                                    min={13}
                                                    max={99}
                                                    size="small"
                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>



                                <Grid item xs={12}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold" color="secondary">
                                        INTERESTS
                                    </SoftTypography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Influencer's Interests
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftSelect
                                                    options={interests}
                                                    isMulti
                                                    size="small"
                                                    value={filter.influencerInterests.map(function (item) {
                                                        return { value: item, label: interests.filter(f => f.value == item)[0]?.label }
                                                    })}
                                                    onChange={event => {
                                                        handleFormInputChange({ name: "influencerInterests", value: event.map(item => item.value) });
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Audience's Interests
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftSelect
                                                    options={interests}
                                                    isMulti
                                                    size="small"
                                                    value={filter.audienceInterests.map(function (item) {
                                                        return { value: item, label: interests.filter(f => f.value == item)[0]?.label }
                                                    })}
                                                    onChange={event => {
                                                        handleFormInputChange({ name: "audienceInterests", value: event.map(item => item.value) });
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold" color="secondary">
                                        INTERACTION
                                    </SoftTypography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Hashtags
                                                    </SoftTypography>
                                                </SoftBox>

                                                <SoftTagInput
                                                    placeholder="Add new tag."
                                                    tags={filter.hashtags}
                                                    onChange={event => {
                                                        handleFormInputChange({
                                                            name: "hashtags", value: event.map(function (item) {
                                                                if (item.indexOf("#") != 0)
                                                                    item = "#" + item;
                                                                return item;
                                                            })
                                                        });
                                                    }}
                                                />

                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Mentions
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftTagInput
                                                    placeholder="Add new tag."
                                                    tags={filter.mentions}
                                                    onChange={event => {
                                                        handleFormInputChange({
                                                            name: "mentions", value: event.map(function (item) {
                                                                if (item.indexOf("@") != 0)
                                                                    item = "@" + item;
                                                                return item;
                                                            })
                                                        });
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>




                                {/* 
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Begin Date
                          </SoftTypography>
                        </SoftBox>

                      </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            End Date
                          </SoftTypography>
                        </SoftBox>

                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid> */}
                                {/* <Grid item xs={12}>
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Campaigns
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                        options={campaigns}
                                        isMulti
                                        value={filter.campaigns.map(function (item) {
                                            return { value: item, label: campaigns.filter(f => f.value == item)[0]?.label }
                                        })}
                                        onChange={event => {
                                            handleFormInputChange({ name: "campaigns", value: event.map(item => item.value) });
                                        }}
                                    />
                                </SoftBox>
                            </Grid> */}
                                {/* <Grid item xs={12}>
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Campaigns
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                        options={campaigns}
                                        isMulti
                                        value={filter.campaigns.map(function (item) {
                                            return { value: item, label: campaigns.filter(f => f.value == item)[0]?.label }
                                        })}
                                        onChange={event => {
                                            handleFormInputChange({ name: "campaigns", value: event.map(item => item.value) });
                                        }}
                                    />
                                </SoftBox>
                            </Grid> */}
                                {/* 
                            <Grid item xs={12} mt={1}>
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Apps
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftSelect
                                        options={apps}
                                        isMulti
                                        value={filter.apps.map(function (item) {
                                            return { value: item, label: apps.filter(f => f.value == item)[0]?.label }
                                        })}
                                        onChange={event => {
                                            handleFormInputChange({ name: "apps", value: event.map(item => item.value) });
                                        }}
                                    />
                                </SoftBox>
                            </Grid> */}



                            </Grid>
                        </SoftBox>

                        <SoftBox display="flex" justifyContent="flex-end" mt={3}>
                            <SoftBox mr={1}>
                                <SoftButton color="light">CLEAR</SoftButton>
                            </SoftBox>
                            <SoftButton variant="gradient" color="info" onClick={() => {
                                onSearchClicked(filter);
                                setOpen(false);
                            }}>
                                APPLY FILTERS
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                </ReactIf>
            </Card>
        </>
    );
}

export default InfluencerSearchBar;
