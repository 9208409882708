/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "../schemas/form";
import { Description } from "@mui/icons-material";


const {
  formField: { campaignName, beginDate, endDate, description, campaignTargetId, webLinkUrl, appStoreAppUrl, googlePlayAppUrl, campaignBudgetTypeId, totalAmount, cpiAmount, cpcAmount,fallbackUrl,campaignBudgetCategoryId},
} = checkout;

const validations = [
  Yup.object().shape({
    [campaignName.name]: Yup.string().required(campaignName.errorMsg),
    [beginDate.name]: Yup.string().required(beginDate.errorMsg),
    [endDate.name]: Yup.string().required(endDate.errorMsg),
    [description.name]: Yup.string().required(description.errorMsg)
  }),
  Yup.object().shape({
    [campaignTargetId.name]: Yup.string().required(campaignTargetId.errorMsg),
    [webLinkUrl.name]: Yup.string().when("campaignTargetId", {
      is: "2",
      then: schema => schema.required(webLinkUrl.errorMsg),
      otherwise: schema => schema.optional(googlePlayAppUrl.errorMsg)
    }),
    [appStoreAppUrl.name]: Yup.string().when("campaignTargetId", {
      is: "1",
      then: schema => schema.required(appStoreAppUrl.errorMsg),
      otherwise: schema => schema.optional(googlePlayAppUrl.errorMsg)
    }),
    [googlePlayAppUrl.name]: Yup.string().when("campaignTargetId", {
      is: "1",
      then: schema => schema.required(googlePlayAppUrl.errorMsg),
      otherwise: schema => schema.optional(googlePlayAppUrl.errorMsg)
    }),
    [fallbackUrl.name]: Yup.string().when("campaignTargetId", {
      is: "1",
      then: schema => schema.required(fallbackUrl.errorMsg),
      otherwise: schema => schema.optional(fallbackUrl.errorMsg)
    })
  }),
  Yup.object().shape({
    [campaignBudgetTypeId.name]: Yup.string().required(campaignBudgetTypeId.errorMsg),
    [campaignBudgetCategoryId.name]: Yup.string().required(campaignBudgetCategoryId.errorMsg),
    [totalAmount.name]: Yup.string().when("campaignBudgetTypeId", {
      is: "2",
      then: schema => schema.required(totalAmount.errorMsg),
      otherwise: schema => schema.optional()
    }),
    [cpiAmount.name]: Yup.string().when("campaignBudgetTypeId", {
      is: "1",
      then: schema => schema.required(cpiAmount.errorMsg),
      otherwise: schema => schema.optional(cpiAmount.errorMsg)
    }),
    [cpcAmount.name]: Yup.string().when("campaignBudgetTypeId", {
      is: "1",
      then: schema => schema.required(cpcAmount.errorMsg),
      otherwise: schema => schema.optional(cpcAmount.errorMsg)
    })
  }),
];

export default validations;
