
import SoftDatePicker from "components/SoftDatePicker";

const DatepickerField = ({
  field, 
  form, 
  ...props
}) => (
  // OR const { setFieldValue } = form;
  // OR const { value, name } = field;
  <div>
    <SoftDatePicker
      dateFormat="dd/MM/yyyy"
      {...field}
      selected={field.value}
      onChange={(val) => form.setFieldValue(field.name, val[0])}
    />
  </div>
);
export default DatepickerField;