/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// ProductPage page components
import ProductImages from "./components/ProductImages";
import ProductInfo from "./components/ProductInfo";

// Data
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { apiUrlCampaigns } from "commonData";
import ReactIf from "reactif";

function CampaignDetail() {
  const [campaignDetail, setCampaignDetail] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {

    axios.post(apiUrlCampaigns + "/campaign-detail", { campaignId: searchParams.get("id") })
      .then((response) => {
        if (response.data.isSuccessful) {

          setCampaignDetail(response.data.data);
        }
      });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <SoftBox mb={3}>
              <SoftTypography variant="h5" fontWeight="medium">
                Campaign Details
              </SoftTypography>
            </SoftBox>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                <Skeleton></Skeleton>
                <ProductImages campaignDetail={campaignDetail} />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                <ReactIf condition={campaignDetail != null}>
                  <ProductInfo campaignDetail={campaignDetail} />
                </ReactIf>
              </Grid>
            </Grid>

            {/* <SoftBox mt={8} mb={2}>
              <SoftBox mb={1} ml={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Similar Campaigns
                </SoftTypography>
              </SoftBox>
              <DataTable
                table={dataTableData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
              />
            </SoftBox> */}
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CampaignDetail;
