/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
 
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Images
import rocket from "assets/images/illustrations/rocket-white.png";

// import Socials from "layouts/authentication/components/Socials";
import SocialLogins from "../components";
import Separator from "layouts/authentication/components/Separator";
import axios from "axios";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { apiUrl } from "commonData";

function SignUpAsInfluencer() {
  const navigate = useNavigate();
  const signIn = useSignIn()
  const [formData, setFormData] = useState({
    fullName: null,
    email: null,
    username: null,
    password: null,
    userTypeId: 2,
    agreement: true
  });

  function navigateTo() {
    navigate("/advertiser/dashboards");
  }

  function submitForm(e) {
    axios.post(apiUrl + "/user/create-user", formData)
      .then((res) => {
        if (res.status === 200 && res.data.isSuccessful === true) {
          submitSignIn(formData.email, formData.password);
        }
      }).catch((res) => {
        if (res.response.data) {

        }
      });
  }

  function submitSignIn(emailOrUsername, password) {
    
    axios.post(apiUrl + "/identity/sign-in", { emailOrUsername, password })
      .then((res) => {
        if (res.status === 200 && res.data.isSuccessful === true) {
          if (signIn({
            auth: {
              token: res.data.data.token,
              type: 'Bearer'
            },
            //refresh: res.data.data.token,
            userState: {
              userType: res.data.data.userType,
              username: res.data.data.username,
              fullName: res.data.data.fullName
            }
          })) { // Only if you are using refreshToken feature
            axios.defaults.headers.common['Authorization'] = "Bearer " + res.data.data.token;
            navigateTo(res.data.userType);
          } else {
            //Throw error
          }
        }
      }).catch((res) => {
        if (res.response.data) {

        }
      });
  }

  return (
    <IllustrationLayout
      title="Sign Up as Influencer"
      description="Sign up your social media accounts and validate your social account"
      illustration={{
        image: rocket,
        title: "Your journey starts here",
        description:"Nords is your powerful value creation tool",
      }}
    >
      <SoftBox mb={2}>
        <SocialLogins />
      </SoftBox>
      <Separator />
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput placeholder="Account Holder's Name" size="large" onChange={(e) => setFormData({ ...formData, fullName: e.target.value })} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" onChange={(e) => setFormData({ ...formData, username: e.target.value, email: e.target.value })} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Password" size="large"  onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Checkbox checked={formData.agreement} onChange={(e) => setFormData({ ...formData, agreement: !formData.agreement })} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={(e) => setFormData({ ...formData, agreement: !formData.agreement })}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;I agree the&nbsp;
          </SoftTypography>
          <SoftTypography component="a" href="#" variant="button" fontWeight="bold" textGradient>
            Terms and Conditions
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" size="large" fullWidth onClick={submitForm}>
            sign up
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Already have an account?&nbsp;
            <SoftTypography
              component={Link}
              to="/sign-in"
              variant="button"
              color="info"
              fontWeight="bold"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Are you an advertiser?&nbsp;
            <SoftTypography
              component={Link}
              to="/sign-up-as-advertiser"
              variant="button"
              color="info"
              fontWeight="bold"
              textGradient
            >
              Sign up as Advertiser
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default SignUpAsInfluencer;
