/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import FormField from "../FormField";
import DatepickerField from "../FormikDatePicker/FormikDatePicker";

function BasicInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { campaignName, description, beginDate, endDate } = formField;
  const {
    campaignName: campaignNameV,
    description: descriptionV,
    beginDate: beginDateV,
    endDate: endDateV
  } = values;

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Start building your best campaign
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Please Fill in the Form
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormField
              type={campaignName.type}
              label={campaignName.label}
              name={campaignName.name}
              value={campaignNameV}
              placeholder={campaignName.placeholder}
              error={errors.campaignName && touched.campaignName}
              success={campaignNameV.length > 0 && !errors.campaignName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              component={DatepickerField}
              type={beginDate.type}
              label={beginDate.label}
              name={beginDate.name}
              value={beginDateV}
              placeholder={beginDate.placeholder}
              error={errors.beginDate && touched.beginDate}
              success={beginDateV.length > 0 && !errors.beginDate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              component={DatepickerField}
              type={endDate.type}
              label={endDate.label}
              name={endDate.name}
              value={endDateV}
              placeholder={endDate.placeholder}
              error={errors.endDate && touched.endDate}
              success={endDateV.length > 0 && !errors.endDate}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              value={descriptionV}
              placeholder={description.placeholder}
              error={errors.description && touched.description}
              success={descriptionV.length > 0 && !errors.description}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={company.type}
              label={company.label}
              name={company.name}
              value={companyV}
              placeholder={company.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
        </Grid> */}
        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={repeatPassword.type}
              label={repeatPassword.label}
              name={repeatPassword.name}
              value={repeatPasswordV}
              placeholder={repeatPassword.placeholder}
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid> */}
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
BasicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BasicInfo;
