/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, Router, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

import { useSearchParams } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import Grid from "@mui/material/Grid";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import chat from "assets/images/illustrations/chat.png";
import axios from 'axios'
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { apiUrl } from "commonData";
import SocialLogins from "../components";
import Separator from "layouts/authentication/components/Separator";
import SoftAlert from "components/SoftAlert";
import ReactIf from "reactif";
function Illustration() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const signIn = useSignIn()
  const [formData, setFormData] = useState({ emailOrUsername: '', password: '' })

  const [rememberMe, setRememberMe] = useState(false);
  const [loginResult, setLoginResult] = useState(null);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  function navigateTo(userType) {
    if (userType == 2)
      navigate("/influencer/dashboard");
    else
      navigate("/advertiser/dashboards");
  }

  useEffect(() => {
    // if (searchParams.get("fl")) 
    // {
      // submitForm(null);
    // }
  }, []
  );

  function submitForm(e) {
    debugger;
    axios.post(apiUrl + "/identity/sign-in", formData/* (e == null ? { emailOrUsername: 'orbay@nords.ai', password: 'sdron123' } : formData)*/)
      .then((res) => {
        if (res.status === 200 && res.data.isSuccessful === true) {
          if (signIn({
            auth: {
              token: res.data.data.token,
              type: 'Bearer'
            },
            //refresh: res.data.data.token,
            userState: {
              userType: res.data.data.userType,
              username: res.data.data.username,
              fullName: res.data.data.fullName
            }
          })) { // Only if you are using refreshToken feature

            axios.defaults.headers.common['Authorization'] = "Bearer " + res.data.data.token;
            navigateTo(res.data.userType);
          } else {
            //Throw error
          }
        }
        else {
          setLoginResult(res.data.message);
        }
      }).catch((res) => {
        setLoginResult(res.response.data.message);
      });
  }

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={{
        image: chat,
        title: 'Sign in',
        description:
          "description...",
      }}
    >

      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" onChange={(e) => setFormData({ ...FormData, emailOrUserName: e.target.value })} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Password" size="large" onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" size="large" fullWidth onClick={submitForm}>
            sign in
          </SoftButton>
        </SoftBox>

        <ReactIf condition={loginResult != null}>
          <SoftAlert color="error" dismissible>
            <SoftTypography variant="caption" color="white">{loginResult}</SoftTypography>
          </SoftAlert>
        </ReactIf>
        <SoftBox mt={3} textAlign="center" >
          <Grid container>
            <Grid item container>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Don&apos;t have an account?
              </SoftTypography>
            </Grid>
            <Grid item container mt={2}>
              <SoftButton
                component={Link}
                to="/sign-up-as-advertiser"
                variant="outlined"
                color="primary"
                fontWeight="medium"

              >
                Sign up as Advertiser
              </SoftButton>
            </Grid>
            <Grid item container mt={2}>
              <SoftButton
                component={Link}
                to="/sign-up-as-influencer"
                variant="gradient"
                color="info"
                fontWeight="bold"
              >
                Sign up as Influencer
              </SoftButton>
            </Grid>
          </Grid>
          <Separator></Separator>
          <SoftBox mb={2}>
            <SocialLogins></SocialLogins>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default Illustration;
