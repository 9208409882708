/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    campaignName: {
      name: "campaignName",
      label: "campaign name",
      type: "text",
      placeholder: "The campaign name is listed to influencers. It should be meaningful in a way that promotes your campaign.",
      errorMsg: "Campaign name is required.",
    },
    beginDate: {
      name: "beginDate",
      label: "begin date",
      type: "text",
      placeholder: "The date your campaign will finish paying influencers for attributions. The date must be at least 1 week from today.",
      errorMsg: "Begin date is required.",
    },
    endDate: {
      name: "endDate",
      label: "end date",
      type: "text",
      placeholder: "The date your campaign will finish paying influencers for attributions. The date must be at least 1 week from today.",
      errorMsg: "End date is required.",
    },
    description: {
      name: "description",
      label: "description",
      placeholder: "Say a few words about who you are or what you're working on.",
    },
    campaignTargetId: {
      name: "campaignTargetId",
      label: "target",
      type: "number",
      placeholder: "7 letters",
      errorMsg: "Target is required.",
      invalidMsg: "Target is not valid",
    },
    webLinkUrl: {
      name: "webLinkUrl",
      label: "Web Link Url",
      type: "text",
      placeholder: "The Web Site link without querystring data.",
      errorMsg: "Web Url is required.",
    },
    appStoreAppUrl: {
      name: "appStoreAppUrl",
      label: "App Store App Url",
      type: "text",
      placeholder: "The App Store link without querystring data.",
      errorMsg: "App Store Url is required.",
    },
    googlePlayAppUrl: {
      name: "googlePlayAppUrl",
      label: "Google Play App Url",
      type: "text",
      placeholder: "The Google Play link without querystring data.",
      errorMsg: "Google Play Url is required.",
    },
    fallbackUrl: {
      name: "fallbackUrl",
      label: "Fallback Url",
      type: "text",
      placeholder: "The Fallback link without querystring data.",
      errorMsg: "Fallback Url is required.",
    },
    campaignBudgetTypeId: {
      name: "campaignBudgetTypeId",
      label: "Budget Type",
      type: "text",
      placeholder: "Budget Type",
      errorMsg: "Budget Type is required.",
    },
    campaignBudgetCategoryId: {
      name: "campaignBudgetCategoryId",
      label: "Budget Category",
      type: "text",
      placeholder: "Budget Category",
      errorMsg: "Budget Category is required.",
    },
    totalAmount: {
      name: "totalAmount",
      label: "Total Amount",
      type: "number",
      placeholder: "Total Budget Amount",
      errorMsg: "Total Amount is required.",
      invalidMsg: "Total Amount is not valid.",
    },
    cpiAmount: {
      name: "cpiAmount",
      label: "CPI Amount",
      type: "number",
      placeholder: "CPI Amount",
      errorMsg: "CPI amount is required.",
      invalidMsg: "CPI Amount is not valid.",
    },
    cpcAmount: {
      name: "cpcAmount",
      label: "CPC Amount",
      type: "number",
      placeholder: "CPC Amount",
      errorMsg: "CPC amount is required.",
      invalidMsg: "CPC Amount is not valid.",
    }
  },
};

export default form;
