/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";



import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// DefaultLineChart configurations
import configs from "examples/Charts/LineCharts/DefaultLineChart/configs";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import breakpoints from "assets/theme/base/breakpoints";

function DefaultLineChart({ title, description, height, chart, onPeriodChanged }) {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
      ...dataset,
      tension: 0.4,
      borderWidth: 3,
      pointRadius: 2,
      pointBackgroundColor: colors[dataset.color]
        ? colors[dataset.color || "dark"].main
        : colors.dark.main,
      borderColor: colors[dataset.color]
        ? colors[dataset.color || "dark"].main
        : colors.dark.main,
      maxBarThickness: 6,
    }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = function (event, newValue) {
    if (onPeriodChanged)
      onPeriodChanged(newValue);
    setTabValue(newValue);
  }

  const renderTab = onPeriodChanged == undefined ? null : (
    <Grid item sm={12} md={3}>
      <AppBar position="static">
        <Tabs
          orientation={tabsOrientation}
          value={tabValue}
          onChange={handleSetTabValue}
          sx={{ background: "transparent" }}
        >
          <Tab label="Hourly" />
          <Tab label="Daily" />
          <Tab label="Weekly" />
        </Tabs>
      </AppBar>
    </Grid>
  );

  const renderChart = (
    <SoftBox p={2}>
      {title || description ? (
        <SoftBox px={description ? 1 : 0} pt={description ? 1 : 0}>

          <Grid container sm={12}>
            <Grid item sm={12} md={9}>
              {title && (
                <SoftBox mb={1}>
                  <SoftTypography variant="h6">{title}</SoftTypography>
                </SoftBox>
              )}
            </Grid>
            {renderTab}
          </Grid>
          <SoftBox mb={2}>
            <SoftTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {useMemo(
        () => (
          <SoftBox height={height}>
            <Line data={data} options={options} />
          </SoftBox>
        ),
        [chart, height]
      )}
    </SoftBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of DefaultLineChart
DefaultLineChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default DefaultLineChart;
