/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { campaignTargets } from "commonData";
import ReactIf from "reactif";
function Targeting({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { campaignTargetId, webLinkUrl, appStoreAppUrl, googlePlayAppUrl, fallbackUrl } = formField;
  const { campaignTargetId: campaignTargetIdV, webLinkUrl: webLinkUrlV, appStoreAppUrl: appStoreAppUrlV, googlePlayAppUrl: googlePlayAppUrlV, fallbackUrl: fallbackUrlV } = values;

  const [targetState, setTargetState] = useState(values.campaignTargetId);

  const handleSetState = (event) => {

    setTargetState(event.target.value);
    values.campaignTargetId = event.target.value;
  };

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Set your Target
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          App installs, Website/Profile visits etc.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container item>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Target
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput />} value={targetState} onChange={handleSetState}>
              {campaignTargets.map((item, index) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <ReactIf condition={targetState == 2}>
          <Grid container mt={1}>
            <Grid item xs={12}>
              <FormField
                type={webLinkUrl.type}
                label={webLinkUrl.label}
                name={webLinkUrl.name}
                value={webLinkUrlV}
                placeholder={webLinkUrl.placeholder}
                error={errors.webLinkUrl && touched.webLinkUrl}
                success={webLinkUrlV.length > 0 && !errors.webLinkUrl}
              />
            </Grid>
          </Grid>
        </ReactIf>
        <ReactIf condition={targetState == 1}>
          <Grid container mt={1}>
            <Grid item xs={12}>
              <FormField
                type={appStoreAppUrl.type}
                label={appStoreAppUrl.label}
                name={appStoreAppUrl.name}
                value={appStoreAppUrlV}
                placeholder={appStoreAppUrl.placeholder}
                error={errors.appStoreAppUrl && touched.appStoreAppUrl}
                success={appStoreAppUrlV.length > 0 && !errors.appStoreAppUrl}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type={googlePlayAppUrl.type}
                label={googlePlayAppUrl.label}
                name={googlePlayAppUrl.name}
                value={googlePlayAppUrlV}
                placeholder={googlePlayAppUrl.placeholder}
                error={errors.googlePlayAppUrl && touched.googlePlayAppUrl}
                success={googlePlayAppUrlV.length > 0 && !errors.googlePlayAppUrl}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type={fallbackUrl.type}
                label={fallbackUrl.label}
                name={fallbackUrl.name}
                value={fallbackUrlV}
                placeholder={fallbackUrl.placeholder}
                error={errors.fallbackUrl && touched.fallbackUrl}
                success={fallbackUrlV.length > 0 && !errors.fallbackUrl}
              />
            </Grid>
          </Grid>
        </ReactIf>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={city.type}
              label={city.label}
              name={city.name}
              value={cityV}
              placeholder={city.placeholder}
              error={errors.city && touched.city}
              success={cityV.length > 0 && !errors.city}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                State
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput />} value={state} onChange={handleSetState}>
              <MenuItem value="...">...</MenuItem>
              <MenuItem value="10">Hello 10</MenuItem>
              <MenuItem value="11">Hello 11</MenuItem>
              <MenuItem value="12">Hello 12</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormField
              type={zip.type}
              label={zip.label}
              name={zip.name}
              value={zipV}
              placeholder={zip.placeholder}
              error={errors.zip && touched.zip}
              success={zipV.length > 0 && !errors.zip}
            />
          </Grid>
        </Grid> */}
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Address
Targeting.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Targeting;
