// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import ReactIf from "reactif";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Card, Modal } from "@mui/material";
import { apiUrlInfluencers } from "commonData";
import { platforms } from "commonData";
import SoftAvatar from "components/SoftAvatar";
import JoinedCampaignUrlItem from "./url-item";

function JoinedCampaignUrls({ joinCampaignResult }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    'border-radius': '10px',
    'border-color': '#ccc'
  };

  const[copied,setCopied]=useState(false);
  return (
    <>
          <Card sx={{ height: "100%" }}>
            <SoftBox pt={2} px={2}>
              <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {joinCampaignResult?.message}
              </SoftTypography>
            </SoftBox>
            <SoftBox p={2}>
              <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                {joinCampaignResult?.data.map((item, index) => (
                    <JoinedCampaignUrlItem urlData={item}></JoinedCampaignUrlItem>
                    ))}
              </SoftBox>
            </SoftBox>
          </Card>
    </>
  );
}

export default JoinedCampaignUrls;
